import { MouseEventHandler, ReactNode } from 'react';

import { User } from '../../../shared/api';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { UserAvatar } from '../../user';
import { GoogleCalendarSettingsTooltip } from './GoogleCalendarSettingsTooltip';

export type FeedHeaderProps = {
  currentDay: Date;
  enableGoogleTooltip?: boolean;
  onAvatarClick?: MouseEventHandler<HTMLElement>;
  rightComponent?: ReactNode;
  titleComponent?: ReactNode;
  user: User;
};
export function FeedHeader({
  currentDay,
  enableGoogleTooltip = false,
  onAvatarClick,
  rightComponent,
  titleComponent,
  user,
}: FeedHeaderProps) {
  const { format } = useLocalisedDateFormat();

  return (
    <div className="flex h-[60px] shrink-0 flex-row items-center justify-between">
      <GoogleCalendarSettingsTooltip
        className="z-[111]"
        disable={!enableGoogleTooltip}
      >
        <div className="h-[60px] w-[72px] px-4 py-2.5">
          <UserAvatar
            className="h-[40px] w-[40px]"
            onClick={onAvatarClick}
            user={user}
          />
        </div>
      </GoogleCalendarSettingsTooltip>
      <div>
        <h2 className="typo-header-small">
          {titleComponent ?? format(currentDay, 'LLLL yyyy')}
        </h2>
      </div>
      <div className="h-[60px] w-[72px] px-4 py-2.5">{rightComponent}</div>
    </div>
  );
}
