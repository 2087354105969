import { cn } from '@tg-web/utils';

import { FreeSlot } from '../../../shared/api';
import { formatSlotTime } from '../lib';
import { OverlapDot } from './OverlapDot';

export interface SlotsListProps {
  onClick: (startTime: string) => void;
  selectedStartTime: string | undefined;
  slots: Array<FreeSlot>;
}

export function SlotsList({
  onClick,
  selectedStartTime,
  slots,
}: SlotsListProps) {
  return slots.map((slot) => (
    <div
      className={cn(
        'bg-tg-section-bg relative flex h-14 cursor-pointer items-center justify-center rounded-2xl p-4',
        {
          'border-tg-button border': selectedStartTime === slot.start_time,
        },
      )}
      key={slot.start_time}
      onClick={() => onClick(slot.start_time)}
    >
      <p className="typo-text-bold mx-auto">
        {formatSlotTime(slot.start_time)} - {formatSlotTime(slot.end_time)}
      </p>
      {slot.overlaps && <OverlapDot className="absolute right-4" />}
    </div>
  ));
}
