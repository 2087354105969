import { Time } from '@internationalized/date';

import { WeekSchedule } from './scheduleSchemas';

export type SlotFormValue = { endTime: Time; startTime: Time };

export const defaultSlot: SlotFormValue = {
  endTime: new Time(18),
  startTime: new Time(9),
};

export const defaultWeekSchedule: WeekSchedule = {
  friday: [defaultSlot],
  monday: [defaultSlot],
  thursday: [defaultSlot],
  tuesday: [defaultSlot],
  wednesday: [defaultSlot],
};
