import { Time } from '@internationalized/date';
import { z } from 'zod';

export const timeIntervalSchema = z
  .object({
    endTime: z.instanceof(Time),
    startTime: z.instanceof(Time),
  })
  .refine(({ endTime, startTime }) => startTime.compare(endTime) < 0);

export const dayScheduleSchema = z.array(timeIntervalSchema);

export const weekScheduleSchema = z.object({
  friday: dayScheduleSchema.optional(),
  monday: dayScheduleSchema.optional(),
  saturday: dayScheduleSchema.optional(),
  sunday: dayScheduleSchema.optional(),
  thursday: dayScheduleSchema.optional(),
  tuesday: dayScheduleSchema.optional(),
  wednesday: dayScheduleSchema.optional(),
});

export type WeekSchedule = z.infer<typeof weekScheduleSchema>;
export type WeekDays = keyof WeekSchedule;
