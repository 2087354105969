import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { captureException } from '@sentry/react';

import { Button } from '@tg-web/components';
import {
  BoringIcon,
  CircleIconWrapper,
  CowSkullIcon,
  TelegramCalendarIcon,
  WatchesIcon,
} from '@tg-web/icons';

import {
  TypedGoogleResponse,
  loadGoogleSdkScript,
} from '../../../features/google';
import {
  ValidateUserTokenRequestBody,
  useValidateUserToken,
} from '../../../shared/api';
import { LocalStoreKeys } from '../../../shared/model/localStoreKeys';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

type AuthState =
  | 'initial'
  | 'permissions-failed'
  | 'session-expired'
  | 'success'
  | 'sww';
type ScreenElements = {
  buttonType?: 'returnToApp' | 'tryAgain';
  description: string;
  icon: ReactNode;
  title: string;
};

type GoogleAuthPageProps = {
  initialGoogleResponse?: TypedGoogleResponse;
  instantLogin?: boolean;
  token: string;
};

export function GoogleAuthPage({
  initialGoogleResponse,
  instantLogin = false,
  token,
}: GoogleAuthPageProps) {
  const { t } = useTranslation();
  const googleScope = import.meta.env.VITE_GOOGLE_SCOPE;

  const [authState, setAuthState] = useState<AuthState>('initial');
  const [isGoogleSdkReady, setIsGoogleSdkReady] = useState(false);
  const [googleResponse, setGoogleResponse] = useState(initialGoogleResponse);
  const [initialLoginState] = useState(
    localStorage.getItem(LocalStoreKeys.GOOGLE_LOGIN_STATE),
  );

  const validateUserToken = useValidateUserToken({
    onError: (error) => {
      if (error.status === 401) {
        setAuthState('session-expired');
      } else {
        setAuthState('sww');
      }
    },
    onSuccess: () => {
      setAuthState('success');
    },
  });

  const gClientRef = useRef<google.accounts.oauth2.CodeClient | undefined>(
    undefined,
  );
  useEffect(() => {
    if (!gClientRef.current) {
      const loginState = crypto.randomUUID();
      localStorage.setItem(LocalStoreKeys.GOOGLE_LOGIN_STATE, loginState);

      loadGoogleSdkScript()
        .then(() => {
          gClientRef.current = google.accounts.oauth2.initCodeClient({
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            redirect_uri: `${window.location.origin}/google-auth-callback`,
            scope: googleScope,
            state: loginState,
            ux_mode: 'redirect',
          });

          setIsGoogleSdkReady(true);
        })
        .catch((error) => {
          captureException(error);
        });
    } else {
      setIsGoogleSdkReady(true);
    }
  }, [googleScope]);

  useEffect(() => {
    if (isGoogleSdkReady && instantLogin && token) {
      gClientRef.current?.requestCode();
    }
  }, [instantLogin, isGoogleSdkReady, token]);

  useEffect(() => {
    if (!googleResponse) {
      return;
    }

    if (googleResponse.type === 'error') {
      if (googleResponse.error === 'access_denied') {
        setAuthState('permissions-failed');
      } else {
        setAuthState('sww');
      }

      captureException(new Error('Google auth response failed'), {
        extra: googleResponse,
      });

      return;
    }

    if (googleResponse.type === 'success') {
      const grantedScopes = googleResponse.scope?.split(' ') ?? [];
      if (
        googleScope.split(' ').some((scope) => !grantedScopes.includes(scope))
      ) {
        setAuthState('permissions-failed');
        return;
      }

      if (googleResponse.state !== initialLoginState) {
        setAuthState('sww');

        captureException(new Error('Google auth state assertion failed'), {
          extra: {
            expected: initialLoginState,
            received: googleResponse.state,
          },
        });

        return;
      }

      const body: ValidateUserTokenRequestBody = {
        google_credentials: googleResponse.code,
        token,
      };

      if (import.meta.env.DEV) {
        body.local = 1;
      }

      validateUserToken.mutate({
        body,
      });
    }

    setGoogleResponse(undefined);
  }, [
    googleResponse,
    googleScope,
    initialLoginState,
    token,
    validateUserToken,
  ]);

  let screenElements: ScreenElements;
  switch (authState) {
    case 'initial':
      screenElements = {
        description: t('all:google_auth.initial.description'),
        icon: (
          <img
            alt="Google calendar icon"
            className="h-12 w-12"
            src="/images/gcal_icon.webp"
          />
        ),
        title: t('all:google_auth.initial.title'),
      };
      break;
    case 'permissions-failed':
      screenElements = {
        buttonType: 'tryAgain',
        description: t('all:google_auth.permissions-failed.description'),
        icon: (
          <CircleIconWrapper>
            <BoringIcon />
          </CircleIconWrapper>
        ),
        title: t('all:google_auth.permissions-failed.title'),
      };
      break;
    case 'session-expired':
      screenElements = {
        buttonType: 'returnToApp',
        description: t('all:google_auth.session-expired.description'),
        icon: (
          <CircleIconWrapper>
            <WatchesIcon />
          </CircleIconWrapper>
        ),
        title: t('all:google_auth.session-expired.title'),
      };
      break;
    case 'success':
      screenElements = {
        description: t('all:google_auth.success.description'),
        icon: (
          <img
            alt="Google calendar icon"
            className="h-12 w-12"
            src="/images/gcal_icon.webp"
          />
        ),
        title: t('all:google_auth.success.title'),
      };
      break;
    case 'sww':
      screenElements = {
        buttonType: 'tryAgain',
        description: t('all:google_auth.sww.description'),
        icon: (
          <CircleIconWrapper>
            <CowSkullIcon />
          </CircleIconWrapper>
        ),
        title: t('all:google_auth.sww.title'),
      };
      break;
  }

  return (
    <PageWrapper className="bg-tg-secondary-bg flex h-dvh flex-col items-center p-6">
      <div className="flex h-full flex-col items-center sm:max-w-[350px]">
        <TelegramCalendarIcon className="text-tg-text" />
        <div className="grow" />
        <div className="flex shrink-0 flex-col items-center text-center">
          {screenElements.icon}
          <h1 className="typo-header-biggest mb-4 mt-6">
            {screenElements.title}
          </h1>
          <p className="typo-text text-tg-subtitle-text mb-8 max-w-[280px] whitespace-pre-wrap text-center">
            {screenElements.description}
          </p>
        </div>
        <div className="max-sm:grow" />
        <div className="max-sm:grow" />
        {screenElements.buttonType === 'tryAgain' && (
          <Button
            onClick={() => {
              gClientRef.current?.requestCode();
            }}
            type="button"
            variant="primary"
          >
            {t('all:common.buttons.try_again')}
          </Button>
        )}
        {screenElements.buttonType === 'returnToApp' && (
          <Button
            onClick={() => {
              window.open(
                `https://t.me/${import.meta.env.VITE_TG_BOT}/${import.meta.env.VITE_TG_MINI_APP}`,
                '_self',
              );
            }}
            size="small"
            type="button"
            variant="tertiary"
          >
            {t('all:google_auth.buttons.go_to_app')}
          </Button>
        )}
        <div className="sm:grow" />
        <div className="sm:grow" />
      </div>
    </PageWrapper>
  );
}
