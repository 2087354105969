import { createContext } from 'react';

import { noop } from '@tg-web/utils';

export const AddStackEventMainButtonStatusContext = createContext<{
  off: VoidFunction;
  on: VoidFunction;
  open: boolean;
}>({
  off: noop,
  on: noop,
  open: true,
});
