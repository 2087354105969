import { createFileRoute } from '@tanstack/react-router';

import { AttendSlotEventPage } from '../../../../pages/slots/ui/AttendSlotEventPage';

export const Route = createFileRoute('/_authorized/slots/$eventId/attend')({
  component: Page,
});

function Page() {
  const { eventId } = Route.useParams();

  return <AttendSlotEventPage eventId={eventId} />;
}
