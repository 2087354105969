import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Calendar,
  CalendarContainer,
  SheetContent,
  SheetTitle,
} from '@tg-web/components';

import { useLocalisedDateFormat } from '../lib/useLocalisedDateFormat';

export type DatePickerSheetContentProps = {
  onClose: () => void;
  onSelect: (selected: Date | undefined) => void;
  open: boolean;
  title: string;
  value?: Date;
};
export function DatePickerSheetContent({
  onClose,
  onSelect,
  open,
  title,
  value,
}: DatePickerSheetContentProps) {
  const { userLocale } = useLocalisedDateFormat();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!open) {
      setSelectedDate(undefined);
    }
  }, [open]);

  const handleSave = () => {
    if (selectedDate) {
      onSelect?.(selectedDate);
    }
    onClose();
  };

  return (
    <SheetContent className="flex flex-col border-none" side="bottom">
      <SheetTitle>{title}</SheetTitle>
      <CalendarContainer
        onCalendarIconClick={() => setSelectedDate(new Date())}
      >
        <Calendar
          className="w-full p-0"
          locale={userLocale}
          mode="single"
          month={selectedDate ?? value}
          onSelect={setSelectedDate}
          selected={selectedDate ?? value}
          fixedWeeks
          required
        />
      </CalendarContainer>
      <Button onClick={handleSave} type="button" variant="primary">
        {t('all:common.buttons.save')}
      </Button>
      <Button onClick={onClose} type="button" variant="secondary">
        {t('all:common.buttons.cancel')}
      </Button>
    </SheetContent>
  );
}
