import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { captureException } from '@sentry/react';

import { useToast } from '@tg-web/components';
import { CopyIcon } from '@tg-web/icons';
import { getWebApp } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { createViewEventDeepLink } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface CopyLinkButtonProps {
  event: Event;
}

export function CopyLinkButton({ event }: CopyLinkButtonProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const toast = useToast();

  const handleClick = async () => {
    const viewEventDeepLink = createViewEventDeepLink(event.attributes.token);

    analytics.track(EventsAnalytics.COPY_LINK, { eventId: event.id });

    try {
      await navigator.clipboard.writeText(viewEventDeepLink);
      getWebApp().HapticFeedback.notificationOccurred('success');
      toast.toast({
        description: t('all:event.copy_link.success'),
      });
    } catch (e) {
      captureException(e);

      toast.toast({
        description: t('all:event.copy_link.error'),
      });
    }
  };

  return (
    <EventActionButton
      icon={<CopyIcon />}
      iconClassName="bg-tg-secondary-bg"
      onClick={handleClick}
      text={t('all:common.buttons.copy_link')}
    />
  );
}
