import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from '@tanstack/react-router';

import {
  SlotBookingProps,
  SlotWithDaysFetcher,
  SlotsInfo,
} from '../../../features/slots';
import { SlotsCalendar } from '../../../features/slots/ui/SlotsCalendar';
import { useCreateSlotEvent } from '../../../shared/api';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export interface ViewSlotPageProps {
  slotId: string;
}

export function ViewSlotsPage({ slotId }: ViewSlotPageProps) {
  const navigate = useNavigate();

  const createSlotEventReq = useCreateSlotEvent();

  const handleSubmit: SlotBookingProps['onSubmitBooking'] = (
    selectedStartTime,
    duration,
  ) => {
    createSlotEventReq.mutate({
      body: {
        duration,
        notify_before: 30,
        start_at: selectedStartTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      pathParams: { slotId },
    });
  };

  useEffect(() => {
    if (createSlotEventReq.isSuccess) {
      navigate({
        params: { eventId: createSlotEventReq.data.event.data.id },
        to: '/slots/$eventId/attend',
      });
    }
  }, [createSlotEventReq.isSuccess]);

  return (
    <PageWrapper className="flex flex-col gap-10 px-5 pb-3 pt-6">
      <SlotWithDaysFetcher slotId={slotId}>
        {({ isSlotWithDaysLoading, selectedDate, setSelectedDate, slot }) => (
          <>
            <SlotsInfo profile={slot.user} slotSize={slot.slot_size} />
            <SlotsCalendar
              isLoading={createSlotEventReq.isPending}
              isSlotWithDaysLoading={isSlotWithDaysLoading}
              onSubmitBooking={handleSubmit}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              slot={slot}
              slotId={slotId}
            />
          </>
        )}
      </SlotWithDaysFetcher>
    </PageWrapper>
  );
}
