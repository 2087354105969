import { ShowEventResponse, User } from '../../../shared/api';

export function getSlotPartnerFromEvent(
  data: ShowEventResponse,
  currentUserId: string,
): User {
  return data.event.included.find(
    (item) => item.type === 'user' && item.id !== currentUserId,
  ) as User;
}
