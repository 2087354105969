import { createFileRoute } from '@tanstack/react-router';

import { RescheduleSlotPage } from '../../../../../pages/slots/ui/RescheduleSlotPage';

export const Route = createFileRoute(
  '/_authorized/slots/$eventId/$slotId/reschedule',
)({
  component: Page,
});

function Page() {
  const { eventId, slotId } = Route.useParams();

  return <RescheduleSlotPage eventId={eventId} slotId={slotId} />;
}
