import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { useToast } from '@tg-web/components';

import { Event, useDeleteEvent } from '../../../shared/api';
import { useFeedOptimisticUpdater } from '../../feed/lib/useFeedOptimisticUpdater';
import { RecurrentType } from '../../recurrency';
import { useDeletePrompt } from './useDeletePrompt';

export interface UseDeleteEventParams {
  event: Event;
  fromFeed: boolean;
}

export function useDeleteEventLogic({ event, fromFeed }: UseDeleteEventParams) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const updateFeedData = useFeedOptimisticUpdater();

  const deletePrompt = useDeletePrompt(
    event.attributes.recurrent_type !== RecurrentType.noRecurrency,
  );
  const toast = useToast();

  const { isPending, isSuccess, mutate } = useDeleteEvent({
    onSuccess: (_, variables) => {
      updateFeedData((feedData) => {
        const updatedPages = feedData.pages.map((page) => {
          return {
            events: {
              data: page.events.data.filter(
                (it) => it.id !== variables.pathParams.id,
              ),
            },
          };
        });

        return {
          pageParams: feedData.pageParams,
          pages: updatedPages,
        };
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      if (event.attributes.stack_id && !fromFeed) {
        navigate({
          params: { stackId: event.attributes.stack_id },
          to: '/stacks/$stackId/view',
        });
      } else {
        navigate({ to: '/feed' });
      }
      setTimeout(() => {
        toast.toast({
          description: t('all:event.delete_dialog.snack'),
          variant: 'feed',
        });
      }, 300);
    }
  }, [isSuccess, navigate]);

  // TODO: handle error

  const handleDelete = async () => {
    const promptStatus = await deletePrompt();
    if (promptStatus === 'close' || promptStatus === 'discard') {
      return;
    }

    let dependentDestroy = 0;
    if (promptStatus === 'secondary') {
      dependentDestroy = 1;
    }

    mutate({
      body: { dependent_destroy: dependentDestroy },
      pathParams: { id: event.id },
    });
  };
  return { handleDelete, isPending };
}
