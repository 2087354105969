import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { EventParticipantsPage } from '../../../../pages/events/ui/EventParticipantsPage';

const participantsSearchSchema = z.object({
  fromFeed: z.boolean(),
});

export const Route = createFileRoute(
  '/_authorized/events/$eventId/participants',
)({
  component: Page,
  validateSearch: zodSearchValidator(participantsSearchSchema),
});

function Page() {
  const { eventId } = Route.useParams();
  const { fromFeed } = Route.useSearch();

  return <EventParticipantsPage eventId={eventId} fromFeed={fromFeed} />;
}
