import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { CheckmarkIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { useAttendEventHandler } from '../lib';
import { AttendStatus, AttendStatusesRequiringOverlap } from '../model';
import { EventActionButton } from './EventActionButton';
import { OverlapEvents } from './OverlapEvents';

export interface JoinEventButtonProps {
  event: Event;
  icon?: ReactNode;
  onSuccess?: () => void;
  text?: string;
}

export function JoinEventButton({
  event,
  icon,
  onSuccess,
  text,
}: JoinEventButtonProps) {
  const overlapModal = useDisclosure();
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const [handleClick, { isPending }] = useAttendEventHandler(
    event,
    AttendStatus.attend,
    onSuccess,
  );

  const handleButtonClick = () => {
    analytics.track(EventsAnalytics.JOIN, { eventId: event.id });

    if (
      AttendStatusesRequiringOverlap.includes(event.attributes.attend_status)
    ) {
      overlapModal.onOpen();
    } else {
      handleClick();
    }
  };

  return (
    <>
      <EventActionButton
        disabled={isPending}
        icon={icon ?? <CheckmarkIcon />}
        iconClassName="bg-tg-button text-tg-button-text"
        onClick={handleButtonClick}
        text={text ?? t('all:common.buttons.join')}
      />
      <OverlapEvents
        duration={event.attributes.duration.toString()}
        enabled={overlapModal.open}
        onCancel={overlapModal.onClose}
        onContinue={handleClick}
        start_at={event.attributes.start_at}
      />
    </>
  );
}
