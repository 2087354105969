import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { GoogleIcon, ShevronRightIcon } from '@tg-web/icons';
import { useBackNavigation } from '@tg-web/utils';

import { GoogleAccountCard, useProfilePolling } from '../../features/google';
import { GlobalLoading } from '../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../shared/ui/PageWrapper';
import { ViewField } from '../../shared/ui/ViewField';

export function SettingsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleStartConnecting,
    hasGoogleCalendar,
    hasProcessingAccounts,
    userProfile,
  } = useProfilePolling();

  useBackNavigation(() => {
    // TODO: handle some query param to navigate back to proper page
    navigate({ to: '/feed' });
  });

  if (userProfile.isPending) {
    return <GlobalLoading />;
  }

  if (userProfile.isError) {
    throw new Error('Unexpected API error');
  }

  const userData = userProfile.data.user.data;

  return (
    <PageWrapper className="flex flex-col gap-6 p-4">
      <h1 className="typo-header-big">{t('all:settings.title')}</h1>
      <GoogleAccountCard
        onConnectStart={handleStartConnecting}
        userData={userData}
      />
      {hasGoogleCalendar && !hasProcessingAccounts && (
        <div>
          <ViewField
            leftIcon={
              <div className="flex h-8 w-8 flex-col items-center justify-center">
                <GoogleIcon />
              </div>
            }
            onClick={() => navigate({ to: '/settings/google-calendars' })}
            rightIcon={<ShevronRightIcon className="text-tg-hint" />}
          >
            {t('all:settings.sections.google_sync')}
          </ViewField>
        </div>
      )}
    </PageWrapper>
  );
}
