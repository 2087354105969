import { PropsWithChildren, useEffect, useRef } from 'react';

import confetti from 'canvas-confetti';

import { PropsWithCN, cn } from '@tg-web/utils';

export function ConfettiFireworks({
  children,
  className,
}: PropsWithChildren & PropsWithCN) {
  const isRunRef = useRef(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current || !parentRef.current) {
      return;
    }

    canvasRef.current.style.width = `${parentRef.current.getBoundingClientRect().width}px`;
    canvasRef.current.style.height = `${parentRef.current.getBoundingClientRect().height}px`;
  }, []);

  const start = () => {
    isRunRef.current = true;

    if (!canvasRef.current) {
      return;
    }

    const duration = 4 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults: confetti.Options = {
      scalar: 0.6,
      spread: 100,
      startVelocity: 10,
      ticks: 60,
      zIndex: 0,
    };

    const randomInRange = (min: number, max: number) =>
      Math.random() * (max - min) + min;

    const confettiElement = confetti.create(canvasRef.current, {
      resize: true,
    });

    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 20 * (timeLeft / duration);
      confettiElement({
        ...defaults,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        particleCount,
      });
      confettiElement({
        ...defaults,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        particleCount,
      });
    }, 250);
  };

  useEffect(() => {
    if (!isRunRef.current) {
      start();
    }
  }, []);

  return (
    <div className={cn('relative', className)} ref={parentRef}>
      <canvas
        className="pointer-events-none absolute left-0 top-0"
        ref={canvasRef}
      />
      {children}
    </div>
  );
}
