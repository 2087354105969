import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { SlotBookingProps, SlotWithDaysFetcher } from '../../../features/slots';
import { SlotEventInfo } from '../../../features/slots/ui/SlotEventInfo';
import { SlotsCalendar } from '../../../features/slots/ui/SlotsCalendar';
import { useUpdateEvent } from '../../../shared/api';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export interface RescheduleSlotPageProps {
  eventId: string;
  slotId: string;
}

export function RescheduleSlotPage({
  eventId,
  slotId,
}: RescheduleSlotPageProps) {
  const { t } = useTranslation();
  const updateEventReq = useUpdateEvent();
  const navigate = useNavigate();

  const handleSubmit: SlotBookingProps['onSubmitBooking'] = (
    selectedStartTime,
    duration,
  ) => {
    updateEventReq.mutate({
      body: {
        duration,
        start_at: selectedStartTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      pathParams: { id: eventId },
    });
  };

  useEffect(() => {
    if (updateEventReq.isSuccess) {
      navigate({
        params: { eventId },
        to: '/slots/$eventId/attend',
      });
    }
  }, [updateEventReq.isSuccess]);

  return (
    <PageWrapper className="flex flex-col gap-6 p-5">
      <h1 className="typo-header-big">{t('all:slots.reschedule.title')}</h1>
      <SlotEventInfo eventId={eventId} />
      <SlotWithDaysFetcher slotId={slotId}>
        {({ isSlotWithDaysLoading, selectedDate, setSelectedDate, slot }) => (
          <SlotsCalendar
            isLoading={updateEventReq.isPending}
            isSlotWithDaysLoading={isSlotWithDaysLoading}
            onSubmitBooking={handleSubmit}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            slot={slot}
            slotId={slotId}
          />
        )}
      </SlotWithDaysFetcher>
    </PageWrapper>
  );
}
