import { useTranslation } from 'react-i18next';

import { endOfDay, startOfDay } from 'date-fns';

import { AvatarCircles, Skeleton } from '@tg-web/components';

import { useListEvent } from '../../../shared/api';
import { TruncateContainer } from '../../../shared/ui/TruncateContainer';
import {
  formatEventTime,
  getEventInterval,
  isFullDayInterval,
} from '../../events';

export interface EventsListProps {
  date: Date;
}

export function EventsList({ date }: EventsListProps) {
  const { t } = useTranslation();
  const eventsListReq = useListEvent({
    queryParams: {
      from: startOfDay(date).toISOString(),
      to: endOfDay(date).toISOString(),
    },
  });

  if (eventsListReq.isError) {
    throw new Error('Unexpected API error');
  }

  if (eventsListReq.isPending) {
    return (
      <>
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
      </>
    );
  }

  const events = eventsListReq.data.events.data;
  if (events.length === 0) {
    return (
      <p className="typo-text my-28 px-12 text-center">
        {t('all:slots.view.schedule.empty')}
      </p>
    );
  }

  return events.map((event) => {
    const { endTime, startTime } = formatEventTime(
      event.attributes.start_at,
      event.attributes.duration,
    );
    const interval = getEventInterval(event);
    const isAllDayEvent = isFullDayInterval(interval);

    return (
      <div
        className="border-03 border-tg-section-separator flex items-center rounded-2xl px-3 py-2"
        key={event.id}
      >
        <div className="flex grow flex-col">
          <span className="typo-text-bold">
            {isAllDayEvent
              ? t('all:feed.event.full_day')
              : `${startTime} - ${endTime}`}
          </span>
          <TruncateContainer bgColor="secondary-bg">
            <span className="typo-text">{event.attributes.title}</span>
          </TruncateContainer>
        </div>
        {event.attributes.attendees_count > 1 && (
          <AvatarCircles
            avatarUrls={event.attributes.attendees_photo_urls.filter(
              (it) => it !== null,
            )}
            className="shrink-0"
            numOfAvatars={3}
            numPeople={event.attributes.attendees_count}
          />
        )}
      </div>
    );
  });
}
