import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { useNavigate } from '@tanstack/react-router';

import { EditIcon } from '@tg-web/icons';

import { Event } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { EventActionButton } from './EventActionButton';

export interface EditEventButtonProps {
  event: Event;
  fromFeed: boolean;
}

export function EditEventButton({ event, fromFeed }: EditEventButtonProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const navigate = useNavigate();

  const handleClick = () => {
    analytics.track(EventsAnalytics.EDIT, { eventId: event.id });

    navigate({
      params: { eventId: event.id },
      search: { fromFeed },
      to: '/events/$eventId/edit',
    });
  };

  return (
    <EventActionButton
      icon={<EditIcon />}
      onClick={handleClick}
      text={t('all:common.buttons.edit')}
    />
  );
}
