import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Label,
  RadioGroup,
  RadioGroupItem,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@tg-web/components';
import { cn } from '@tg-web/utils';

import {
  NotificationDuration,
  NotificationDurationDescriptions,
  useNotificationOptions,
} from '../lib';

const formSchema = z.object({
  value: z.string(),
});

export type NotificationModalProps = {
  cancelButtonText?: string;
  initial?: NotificationDuration;
  isRecurringEvent?: boolean;
  onClose?: () => void;
  onSave?: (value: NotificationDuration, dependentNotify: 0 | 1) => void;
  open?: boolean;
  title?: string;
};

export function NotificationModal({
  cancelButtonText,
  initial = NotificationDuration.noNotify,
  isRecurringEvent = false,
  onClose,
  onSave,
  open = false,
  title,
}: NotificationModalProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      value: initial.toString(),
    },
    resolver: zodResolver(formSchema),
  });

  const { notificationOptions } = useNotificationOptions();

  useEffect(() => {
    if (open) {
      form.reset();
      form.setValue('value', initial.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    onSave?.(parseInt(values.value), 0);
    onClose?.();
  }

  function handleSubmitForAll(values: z.infer<typeof formSchema>) {
    onSave?.(parseInt(values.value), 1);
    onClose?.();
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.();
    }
  };

  return (
    <Sheet onOpenChange={handleOpenChange} open={open}>
      <SheetContent className="w-full border-none" side="bottom">
        <SheetHeader>
          <SheetTitle>
            {title ?? t('all:event.notifications.once.title')}
          </SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormControl>
                      <RadioGroup
                        className="-mx-5 mt-6"
                        defaultValue={field.value}
                        onValueChange={field.onChange}
                      >
                        {notificationOptions.map((option) => (
                          <div
                            className={cn(
                              'flex h-12 items-center px-5',
                              field.value === option.value &&
                                'bg-tg-secondary-bg',
                            )}
                            key={option.value}
                          >
                            <Label
                              className="grow"
                              htmlFor={`r${option.value}`}
                            >
                              {option.label}
                            </Label>
                            <RadioGroupItem
                              id={`r${option.value}`}
                              value={option.value}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                  {!isRecurringEvent && (
                    <p className="typo-text mt-4 text-center">
                      {t(
                        NotificationDurationDescriptions[
                          parseInt(field.value) as NotificationDuration
                        ],
                      )}
                    </p>
                  )}
                </>
              )}
              control={form.control}
              name="value"
            />
            <Button className="mt-8" variant="primary">
              {t('all:event.notifications.once.save')}
            </Button>
            {isRecurringEvent && (
              <Button
                className="mt-3"
                onClick={form.handleSubmit(handleSubmitForAll)}
                type="button"
                variant="tertiary"
              >
                {t('all:event.notifications.recurrent.save')}
              </Button>
            )}
            <Button
              className="mt-3"
              onClick={onClose}
              type="button"
              variant="secondary"
            >
              {cancelButtonText ?? t('all:common.buttons.cancel')}
            </Button>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
