import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Textarea,
} from '@tg-web/components';

const formSchema = z.object({
  value: z.string().min(0),
});

export type DescriptionModalProps = {
  initialValue?: string;
  onClose?: () => void;
  onSave?: (value: string) => void;
  open?: boolean;
  placeholder?: string;
  title: string;
  validationSchema?:
    | z.ZodObject<
        { value: z.ZodString | z.ZodUnion<[z.ZodString, z.ZodLiteral<''>]> },
        'strip',
        z.ZodTypeAny,
        { value: string },
        { value: string }
      >
    | undefined;
};

export function TextAreaModal({
  initialValue = '',
  onClose,
  onSave,
  open = false,
  placeholder,
  title,
  validationSchema,
}: DescriptionModalProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      value: initialValue,
    },
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema ?? formSchema),
  });

  useEffect(() => {
    if (open) {
      form.reset();
      form.setValue('value', initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    onSave?.(values.value.trim());
    onClose?.();
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.();
    }
  };

  return (
    <Sheet onOpenChange={handleOpenChange} open={open}>
      <SheetContent
        className="flex h-full w-full flex-col gap-6 overflow-y-scroll rounded-none border-none"
        side="right"
      >
        <Form {...form}>
          <form
            className="flex h-full w-full flex-col gap-5"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <SheetHeader>
              <SheetTitle>{title}</SheetTitle>
            </SheetHeader>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea {...field} placeholder={placeholder} rows={10} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              control={form.control}
              name="value"
            />
            <Button className="sticky bottom-0" variant="primary">
              {t('all:common.buttons.save')}
            </Button>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
