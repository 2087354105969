import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { DialogProps } from '@radix-ui/react-dialog';

import {
  Button,
  Sheet,
  SheetContent,
  SheetTrigger,
  useToast,
} from '@tg-web/components';
import { PinIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Stack, useShareStackViaBot } from '../../../shared/api';
import { StacksAnalytics } from '../../../shared/model/analytics';
import { useCopyStackLink } from '../lib';
import shareImg from './images/share.webp';

export interface ShareStackModalProps extends DialogProps {
  stackId: Stack['id'];
  trigger?: ReactNode;
}

export function ShareStackModal({
  onOpenChange,
  stackId,
  trigger,
  ...sheetProps
}: ShareStackModalProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const toast = useToast();
  const { onClose, onOpen, onToggle, open } = useDisclosure();
  const { copyStackLink } = useCopyStackLink(stackId);
  const shareStackReq = useShareStackViaBot();

  const handleShareButtonClick = () => {
    analytics.track(StacksAnalytics.SHARE_VIA_BOT, { stackId });

    shareStackReq.mutate({ pathParams: { stackId } });
  };
  const handleChange: DialogProps['onOpenChange'] = (value) => {
    onOpenChange?.(value);
    onToggle();
  };

  useEffect(() => {
    if (shareStackReq.isSuccess) {
      if (onOpenChange) {
        onOpenChange(false);
      }
      onClose();
      toast.toast({
        description: t('all:stacks.share.success'),
      });
    }
  }, [shareStackReq.isSuccess]);

  return (
    <Sheet onOpenChange={handleChange} open={open} {...sheetProps}>
      {trigger && (
        <SheetTrigger onClick={onOpen} asChild>
          {trigger}
        </SheetTrigger>
      )}
      <SheetContent
        className="bg-tg-section-bg flex flex-col gap-0 px-5 py-10"
        side="bottom"
      >
        <span className="typo-header-big text-center">
          {t('all:stacks.share.title')}
        </span>
        <img
          alt={t('all:stacks.share.title')}
          className="mt-9"
          src={shareImg}
        />
        <div className="flex gap-3 py-2">
          <PinIcon className="shrink-0" />
          <span className="typo-text">{t('all:stacks.share.description')}</span>
        </div>
        <div className="flex flex-col gap-3 pt-4">
          <Button
            disabled={shareStackReq.isPending}
            onClick={handleShareButtonClick}
            variant="primary"
          >
            {t('all:stacks.share.send_me')}
          </Button>
          <Button onClick={copyStackLink} variant="secondary">
            {t('all:stacks.share.copy_link.button')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}
