import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { useUserLocale } from './useUserLocale';

const FormatStringMapping: Record<string, string> = {
  E: 'EEEEEE',
};

export function useLocalisedDateFormat() {
  const { userLocale } = useUserLocale();
  const localisedFormat: typeof format = (date, formatStr, options) =>
    format(
      date,
      userLocale === ru
        ? (FormatStringMapping[formatStr] ?? formatStr)
        : formatStr,
      {
        ...options,
        locale: userLocale,
      },
    );

  return {
    format: localisedFormat,
    userLocale,
  };
}
