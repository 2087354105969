import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NotificationDurationArray,
  NotificationDurationLabels,
} from './duration';

export function useNotificationOptions() {
  const { t } = useTranslation();

  const notificationOptions = useMemo(() => {
    return NotificationDurationArray.map((value) => ({
      label: t(NotificationDurationLabels[value]),
      value: value.toString(),
    }));
  }, []);

  return { notificationOptions };
}
