import {
  Locale,
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
} from 'date-fns';
import { ru } from 'date-fns/locale';

type WeekDays = Array<{ key: string; label: string }>;

export const getWeekDays = (locale: Locale): WeekDays => {
  const now = new Date();
  const weekDays: WeekDays = [];
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  eachDayOfInterval({ end, start }).forEach((day) => {
    weekDays.push({
      key: format(day, 'EEEE').toLowerCase(),
      label: format(day, locale === ru ? 'EEEEEE' : 'E', { locale }),
    });
  });
  return weekDays;
};
