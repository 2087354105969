import { enGB, ru } from 'date-fns/locale';

import { useAppLang } from './useAppLang';

const LangToLocaleNap = {
  en: enGB,
  ru,
} as const;

export function useUserLocale() {
  const { appLang } = useAppLang();
  const userLocale = LangToLocaleNap[appLang];

  return { userLocale };
}
