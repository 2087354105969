import { motion } from 'framer-motion';

export function NewLabel() {
  return (
    <motion.div
      animate={{
        rotate: [0, -10, 0, 10, 0],
        scale: [1, 1.2, 1, 1.2, 1],
      }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatDelay: 5,
      }}
      className="text-tg-button-text typo-small relative -right-1 -top-0.5 rounded-2xl bg-[#FF2DD1] px-2.5 py-1.5"
      style={{ display: 'inline-block' }}
    >
      New
    </motion.div>
  );
}
