import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { useMatch, useNavigate } from '@tanstack/react-router';

import { useToast } from '@tg-web/components';

import { useUserProfileConfigured } from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { setCloudStorageItem } from '../../../shared/lib/setCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

export function GoogleConnectionHandler({ children }: PropsWithChildren) {
  const { t } = useTranslation();

  const match = useMatch({
    from: '/_authorized/settings/',
    shouldThrow: false,
  });
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userProfile = useUserProfileConfigured();
  const { toast } = useToast();

  useEffect(() => {
    if (
      userProfile.isSuccess &&
      userProfile.data.user.data.attributes.has_google_calendar
    ) {
      getCloudStorageItem(
        CloudStorageKeys.GOOGLE_CALENDAR_ONBOARDING_PASSED,
      ).then((value) => {
        if (!value) {
          queryClient.removeQueries({
            queryKey: ['events'],
          });
          queryClient
            .invalidateQueries({
              queryKey: ['google_accounts'],
            })
            .then(() => {
              let toastParams: Parameters<typeof toast>[0] = {
                description: t('all:google.link_notification.short'),
                variant: 'default',
              };

              if (!match) {
                toastParams = {
                  description: t('all:google.link_notification.long'),
                  variant: 'feed',
                };
              }

              toast(toastParams);

              setCloudStorageItem(
                CloudStorageKeys.GOOGLE_CALENDAR_ONBOARDING_PASSED,
                'true',
              );
            });
        }
      });
    }
  }, [
    navigate,
    queryClient,
    userProfile.data?.user.data.attributes.has_google_calendar,
    userProfile.isSuccess,
  ]);

  return children;
}
