import * as React from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { type VariantProps, cva } from 'class-variance-authority';

import { ShevronDownIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    className={cn('border-b', className)}
    ref={ref}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionHeader = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Header>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Header>
>(({ children, className, ...props }, ref) => (
  <AccordionPrimitive.Header
    className={cn('flex', className)}
    ref={ref}
    {...props}
  >
    {children}
  </AccordionPrimitive.Header>
));
AccordionHeader.displayName = AccordionPrimitive.Header.displayName;

const chevronVariants = cva('shrink-0 transition-transform duration-200', {
  defaultVariants: {
    size: 'small',
  },
  variants: {
    size: {
      big: 'h-8 w-8',
      small: 'h-4 w-4',
    },
  },
});

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    chevronSize?: VariantProps<typeof chevronVariants>['size'];
    withoutHeader?: boolean;
  }
>(
  (
    { chevronSize, children, className, withoutHeader = false, ...props },
    ref,
  ) => {
    const Component = withoutHeader ? 'div' : AccordionPrimitive.Header;
    return (
      <Component className="flex">
        <AccordionPrimitive.Trigger
          className={cn(
            'flex flex-1 items-center justify-between py-4 transition-all [&[data-state=open]>svg]:rotate-180',
            className,
          )}
          ref={ref}
          {...props}
        >
          {children}
          <ShevronDownIcon
            className={chevronVariants({ size: chevronSize })}
            rotate={90}
          />
        </AccordionPrimitive.Trigger>
      </Component>
    );
  },
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, ref) => (
  <AccordionPrimitive.Content
    className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm"
    ref={ref}
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
