import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { Skeleton, Switch } from '@tg-web/components';

import {
  useDisableGoogleMeetForSlot,
  useEnableGoogleMeetForSlot,
  useGoogleCalendarAccountConfigured,
} from '../../../shared/api';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { SlotsGooglePageHeader } from './SlotsGooglePageHeader';

export function SlotsGoogleSettingsScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { googleAccountsList } = useGoogleCalendarAccountConfigured();
  const [googleMeetEnabled, setGoogleMeetEnabled] = useState(true);
  const enableGoogleMeetReq = useEnableGoogleMeetForSlot();
  const disableGoogleMeetReq = useDisableGoogleMeetForSlot();

  useMainButton({
    onClick: () => {
      if (googleMeetEnabled) {
        enableGoogleMeetReq.mutate({});
      } else {
        disableGoogleMeetReq.mutate({});
      }
    },
    text: t('all:common.buttons.continue'),
  });

  useEffect(() => {
    if (enableGoogleMeetReq.isSuccess || disableGoogleMeetReq.isSuccess) {
      navigate({ to: '/slots' });
    }
  }, [enableGoogleMeetReq.isSuccess, disableGoogleMeetReq.isSuccess]);

  return (
    <PageWrapper className="flex flex-col gap-6 px-5 py-3">
      <SlotsGooglePageHeader />
      <div className="flex flex-col gap-6">
        {googleAccountsList.isSuccess &&
        googleAccountsList.data.google_accounts.data.length > 0 ? (
          <div className="bg-tg-section-bg flex items-center gap-[18px] rounded-2xl px-[18px] py-5">
            <img
              alt="Google calendar icon"
              className="h-11 w-11"
              src="/images/gcal_icon.webp"
            />
            <div>
              <p className="typo-text-bold">
                {t('all:slots.google.settings.connected_account')}
              </p>
              <p className="typo-text mt-0.5">
                {
                  googleAccountsList.data.google_accounts.data[0].attributes
                    .email
                }
              </p>
            </div>
          </div>
        ) : (
          <Skeleton className="bg-tg-section-bg h-[84px] w-full rounded-2xl" />
        )}

        <div>
          <p className="typo-text-bold">
            {t('all:slots.google.settings.add_link')}
          </p>
          <div className="bg-tg-section-bg mt-3 flex items-center gap-3 rounded-2xl p-3">
            <img
              alt="Google calendar icon"
              className="h-8 w-8"
              src="/images/gmeet_icon.webp"
            />
            <span className="typo-text">
              {t('all:slots.google.settings.g_meet')}
            </span>
            <Switch
              checked={googleMeetEnabled}
              className="ml-auto"
              onCheckedChange={setGoogleMeetEnabled}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
