import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { getWebApp } from '@tg-web/utils';

import { hexToHsl } from '../lib/hexToHsl';
import { setAppBackground } from '../lib/setAppBackground';
import { getTgTheme } from '../model/defaultTheme';
import { TelegramPlatforms } from '../model/tgPlatforms';

type Theme = 'dark' | 'light' | 'system';

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  appTheme: 'dark' | 'light';
  setTheme: (theme: Theme) => void;
  theme: Theme;
};

const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
  ? 'dark'
  : 'light';

const initialState: ThemeProviderState = {
  appTheme: systemTheme,
  setTheme: () => null,
  theme: 'system',
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = 'system',
  storageKey = 'vite-ui-theme',
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () =>
      getWebApp().colorScheme ||
      (localStorage.getItem(storageKey) as Theme) ||
      defaultTheme,
  );

  useLayoutEffect(() => {
    getWebApp().disableVerticalSwipes();
    getWebApp().expand();
  }, []);

  useLayoutEffect(() => {
    if (getWebApp().platform === TelegramPlatforms.unknown) {
      return;
    }

    const isDarkTheme = getWebApp().colorScheme === 'dark';

    setAppBackground({});

    const style = document.createElement('style');
    const accentColor = hexToHsl(getTgTheme().accent_text_color);
    const customParamsOverride = [
      `--custom_event_bg_color: ${isDarkTheme ? '240 2% 18%' : `${accentColor.h} 100% 90%`};`,
      `--custom_button_active_color: ${accentColor.h} ${accentColor.s}% ${Math.max(0, accentColor.l - 14)}%;`,
    ].join(' ');
    const stylesForVarsOverride = `
    ${isDarkTheme ? '.dark' : ':root'} {
    ${Object.entries(getTgTheme()).reduce((acc, [key, value]) => {
      const { h, l, s } = hexToHsl(value);
      return `${acc}--${key}: ${h} ${s}% ${l}%; `;
    }, customParamsOverride)}
    }`;
    style.textContent = stylesForVarsOverride;
    document.head.append(style);
  }, []);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = {
    appTheme: theme === 'system' ? systemTheme : theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme);
      setTheme(theme);
    },
    theme,
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
