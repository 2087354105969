import { createFileRoute } from '@tanstack/react-router';

import { ViewSlotsPage } from '../../../../pages/slots/ui/ViewSlotsPage';

export const Route = createFileRoute('/_authorized/slots/$slotId/view')({
  component: Page,
});

function Page() {
  const { slotId } = Route.useParams();

  return <ViewSlotsPage slotId={slotId} />;
}
