import { useTranslation } from 'react-i18next';

import {
  differenceInMinutes,
  endOfDay,
  isSameDay,
  isSameMinute,
  isSameYear,
  startOfDay,
  subMinutes,
} from 'date-fns';

import { Event, StackEvent } from '../../../shared/api';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { MINUTES_IN_DAY } from '../model';
import { fixFullDayEventStart } from './fixFullDayEventStart';
import { getEventInterval } from './getEventInterval';
import { isFullDayInterval } from './isFullDayInterval';
import { isStackEvent } from './isStackEvent';

export function useFormatEventDateAndTime() {
  const { t } = useTranslation();
  const { format } = useLocalisedDateFormat();

  return {
    formatEventDateAndTime: (
      event: Event | StackEvent,
      hideFirstDayDate = false,
    ) => {
      const { end, start } = getEventInterval(
        isStackEvent(event) ? event : fixFullDayEventStart(event),
      );

      const isFullDay = isFullDayInterval({ end, start });
      const isStartSameYear = isSameYear(start, new Date());

      if (isSameDay(start, end) || isFullDay) {
        const formatedEventTime = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;

        const dateSrt = `${format(start, 'EEEE, dd MMM')}${isStartSameYear ? '' : format(start, ' yyy')} · `;

        return [
          hideFirstDayDate ? '' : dateSrt,
          isFullDay ? t('all:feed.event.full_day') : formatedEventTime,
        ].join('');
      }

      const startDateStr = hideFirstDayDate
        ? ''
        : `${format(start, 'EEEE, dd MMM')}${isStartSameYear ? '' : format(start, ' yyy')}`;
      const startDateTime = isSameMinute(start, startOfDay(start))
        ? t('all:feed.event.full_day')
        : format(start, 'HH:mm');

      const endOfPeriod =
        differenceInMinutes(end, start) > MINUTES_IN_DAY &&
        isSameMinute(end, startOfDay(end))
          ? subMinutes(end, 1)
          : end;
      const isEndSameYear = isSameYear(endOfPeriod, new Date());

      const endDateStr = `${format(endOfPeriod, 'EEEE, dd MMM')}${isEndSameYear ? '' : format(endOfPeriod, ' yyy')}`;
      const endDateTime = isSameMinute(endOfPeriod, endOfDay(endOfPeriod))
        ? t('all:feed.event.full_day')
        : format(endOfPeriod, 'HH:mm');

      return `${[startDateStr, startDateTime].filter(Boolean).join(' · ')} - \n${[endDateStr, endDateTime].join(' · ')}`;
    },
  };
}
