import { ReactNode, useState } from 'react';

import { format } from 'date-fns';

import { SlotWithDays, useShowSlotWithDays } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';

export interface SlotWithDaysFetcherProps {
  children: (params: {
    isSlotWithDaysLoading: boolean;
    selectedDate: Date | undefined;
    setSelectedDate: (date: Date | undefined) => void;
    slot: SlotWithDays;
  }) => ReactNode;
  slotId: string;
}

export function SlotWithDaysFetcher({
  children,
  slotId,
}: SlotWithDaysFetcherProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const slotWithDaysReq = useShowSlotWithDays(
    {
      pathParams: { id: slotId },
      queryParams: { date: format(selectedDate ?? new Date(), 'yyyy-MM-01') },
    },
    {
      placeholderData: (previousData) => previousData,
    },
  );

  if (slotWithDaysReq.isError) {
    throw new Error('Unexpected API error');
  }

  const slot = slotWithDaysReq.data?.slot;

  if (!slot) {
    return <GlobalLoading />;
  }

  return children({
    isSlotWithDaysLoading: slotWithDaysReq.isFetching,
    selectedDate,
    setSelectedDate,
    slot,
  });
}
