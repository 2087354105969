import { MouseEventHandler } from 'react';

import { Avatar, AvatarFallback, AvatarImage } from '@tg-web/components';
import { PropsWithCN, cn } from '@tg-web/utils';

import { SmallUser, User } from '../../../shared/api';
import { isSmallUser } from '../lib';

export type UserAvatarProps = PropsWithCN & {
  fallbackClassName?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  user: SmallUser | User;
};
export function UserAvatar({
  className,
  fallbackClassName,
  onClick,
  user,
}: UserAvatarProps) {
  const photoUrl = isSmallUser(user)
    ? user.photo_url
    : user.attributes.profile_photo_url;
  const firstName = isSmallUser(user)
    ? (user.first_name ?? '')
    : user.attributes.first_name;
  const lastName = isSmallUser(user)
    ? user.last_name
    : user.attributes.last_name;
  return (
    <Avatar className={cn('', className)} onClick={onClick}>
      {photoUrl && <AvatarImage src={photoUrl} />}
      <AvatarFallback className={cn('uppercase', fallbackClassName)}>
        {firstName[0]}
        {lastName?.[0]}
      </AvatarFallback>
    </Avatar>
  );
}
