import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsWithCN, cn } from '@tg-web/utils';

export interface StepperProps extends PropsWithCN {
  activeIndex: number;
  count: number;
}

export function Stepper({ activeIndex, className, count }: StepperProps) {
  const { t } = useTranslation();
  return (
    <div className={cn(className, 'flex flex-col gap-2')}>
      <p className="typo-text text-tg-section-header-text">
        {t('all:common.ui.stepper.text', {
          activeIndex: activeIndex + 1,
          count,
        })}
      </p>
      <div className="flex justify-between gap-2">
        {Array(count)
          .fill(null)
          .map((_, index) => (
            <div
              className={cn('bg-tg-section-bg h-1 flex-grow rounded-full', {
                'bg-tg-button': index <= activeIndex,
              })}
              key={index}
            />
          ))}
      </div>
    </div>
  );
}
