import {
  useGoogleAuthHandler,
  useProfilePolling,
} from '../../../features/google';
import {
  SlotsGoogleSettingsScreen,
  SlotsGoogleWelcomeScreen,
} from '../../../features/slots';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';

export function SlotsGoogleInitPage() {
  const { handleStartConnecting, userProfile } = useProfilePolling();
  const { createUserToken, googleAuthHandler } = useGoogleAuthHandler(
    handleStartConnecting,
  );

  if (userProfile.isPending) {
    return <GlobalLoading />;
  }

  if (userProfile.isError) {
    throw new Error('Unexpected API error');
  }

  const userData = userProfile.data.user.data;

  return userData.attributes.has_google_calendar ? (
    <SlotsGoogleSettingsScreen />
  ) : (
    <SlotsGoogleWelcomeScreen
      isLoading={createUserToken.isPending}
      onContinueButtonClick={googleAuthHandler}
    />
  );
}
