export enum CommonAnalytics {
  APP_OPEN = 'appOpen',
}

export enum EventsAnalytics {
  ADD_TO_GOOGLE = 'eventActionAddToGoogle',
  CHANGE_NOTIFICATION = 'eventActionChangeNotification',
  COPY_LINK = 'eventActionCopyLink',
  DELETE = 'eventActionDelete',
  EDIT = 'eventActionEdit',
  JOIN = 'eventActionJoin',
  MAYBE = 'eventActionMaybe',
  NOT_INTERESTED = 'eventActionNotInterested',
  SHARE = 'eventActionShare',
}

export enum StacksAnalytics {
  COPY_LINK = 'stackActionCopyLink',
  SHARE_VIA_BOT = 'stackActionShareLinkViaBot',
}
