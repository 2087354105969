import * as React from 'react';

import { cn } from '@tg-web/utils';

export const baseInputClassNames = cn(
  'bg-tg-section-bg border-tg-section-bg typo-text flex h-14 w-full rounded-2xl border px-3 py-3 transition-colors',
  'focus-visible:border-tg-section-separator focus-visible:outline-none',
  'focus-within:border-tg-section-separator focus-within:outline-none',
  'placeholder:text-tg-hint',
  'file:border-0 file:bg-transparent file:text-sm file:font-medium',
  'disabled:cursor-not-allowed disabled:opacity-50',
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, leftIcon, rightIcon, type, ...props }, ref) => {
    return (
      <div className="relative">
        {leftIcon && (
          <div className="absolute left-3 top-3 h-[32px] w-[32px]">
            {leftIcon}
          </div>
        )}
        <input
          className={cn(
            baseInputClassNames,
            Boolean(leftIcon) && 'pl-[54px]',
            Boolean(rightIcon) && 'pr-[54px]',
            className,
          )}
          ref={ref}
          type={type}
          {...props}
        />
        {rightIcon && (
          <div className="absolute right-3 top-3 h-[32px] w-[32px]">
            {rightIcon}
          </div>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
