import { PropsWithChildren } from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

const BgMap = {
  'event-bg': 'to-tg-secondary-bg',
  'secondary-bg': 'to-tg-secondary-bg',
  'section-bg': 'to-tg-section-bg',
} as const;

export type TruncateContainerProps = PropsWithChildren &
  PropsWithCN & {
    bgColor: keyof typeof BgMap;
  };

export function TruncateContainer({
  bgColor,
  children,
  className,
}: TruncateContainerProps) {
  return (
    <div className={cn('relative overflow-hidden text-nowrap', className)}>
      {children}
      <div
        className={cn(
          'pointer-events-none absolute bottom-0 right-0 top-0 w-10 bg-gradient-to-r from-transparent',
          BgMap[bgColor],
        )}
      />
    </div>
  );
}
