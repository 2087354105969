import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SmallUser, User } from '../../../shared/api';
import { UserAvatar, formatFullName } from '../../user';

export interface SlotsInfoProps {
  profile: SmallUser | User;
  slotSize: number;
}

export function SlotsInfo({ profile, slotSize }: SlotsInfoProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <UserAvatar className="h-[64px] w-[64px]" user={profile} />
      <p className="typo-text mt-5">
        {t('all:slots.main.book_with', { duration: slotSize })}
      </p>
      <h1 className="typo-header-big mt-0.5">{formatFullName(profile)}</h1>
    </div>
  );
}
