import * as React from 'react';
import { useMemo } from 'react';

import { isBefore, startOfDay } from 'date-fns';

import {
  Calendar,
  CalendarContainer,
  buttonVariants,
} from '@tg-web/components';
import { cn } from '@tg-web/utils';

import { SlotBooking, SlotBookingProps } from '../../../features/slots';
import { SlotsIsOffPanel } from '../../../features/slots/ui/SlotsIsOffPanel';
import { SlotWithDays } from '../../../shared/api';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';

export interface SlotsCalendarProps
  extends Pick<SlotBookingProps, 'isLoading' | 'onSubmitBooking'> {
  isSlotWithDaysLoading: boolean;
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date | undefined) => void;
  slot: SlotWithDays;
  slotId: string;
}

export function SlotsCalendar({
  isLoading,
  isSlotWithDaysLoading,
  onSubmitBooking,
  selectedDate,
  setSelectedDate,
  slot,
  slotId,
}: SlotsCalendarProps) {
  const { userLocale } = useLocalisedDateFormat();

  const { daysWithSlots, disabledDays } = useMemo(() => {
    const daysWithSlots: Array<Date> = [];
    const disabledDays: Array<Date> = [];
    if (!slot.days) {
      return {
        daysWithSlots,
        disabledDays,
      };
    }
    slot.days.forEach((day) => {
      const dayAsDate = new Date(day.date);
      if (day.has_slots && !isBefore(day.date, startOfDay(new Date()))) {
        daysWithSlots.push(dayAsDate);
      } else {
        disabledDays.push(dayAsDate);
      }
    });
    return {
      daysWithSlots,
      disabledDays,
    };
  }, [slot.days]);

  return (
    <>
      {slot.state === 'enabled' ? (
        <CalendarContainer
          iconVariant="white"
          onCalendarIconClick={() => setSelectedDate(new Date())}
        >
          <Calendar
            classNames={{
              button_next: cn(
                buttonVariants({ variant: 'white' }),
                'h-10 w-10 p-0 rounded-full',
              ),
              button_previous: cn(
                buttonVariants({ variant: 'white' }),
                'h-10 w-10 p-0 rounded-full',
              ),
            }}
            modifiersClassNames={{
              daysWithSlots:
                'rounded-lg bg-tg-section-bg shadow-days-with-slots',
              disabled: 'text-tg-text [&>button:disabled]:opacity-100',
              pending: 'opacity-50',
            }}
            className="w-full p-0"
            disabled={[{ before: new Date() }, ...disabledDays]}
            locale={userLocale}
            mode="single"
            modifiers={{ daysWithSlots, pending: isSlotWithDaysLoading }}
            month={selectedDate}
            onMonthChange={setSelectedDate}
            onSelect={setSelectedDate}
            selected={selectedDate}
            showOutsideDays={false}
            required
          />
        </CalendarContainer>
      ) : (
        <SlotsIsOffPanel />
      )}
      {selectedDate && (
        <SlotBooking
          date={selectedDate}
          isLoading={isLoading}
          onSubmitBooking={onSubmitBooking}
          slotId={slotId}
        />
      )}
    </>
  );
}
