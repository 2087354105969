import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { useToast } from '@tg-web/components';
import { getWebApp } from '@tg-web/utils';

import { Stack } from '../../../shared/api';
import { StacksAnalytics } from '../../../shared/model/analytics';
import { StartParams } from '../../../shared/model/startParamsStore';

export function useCopyStackLink(stackId: Stack['id']) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const toast = useToast();

  const copyStackLink = async () => {
    const startParams: StartParams = {
      id: stackId,
      mode: 'viewStack',
    };
    const link = `https://t.me/${import.meta.env.VITE_TG_BOT}/${import.meta.env.VITE_TG_MINI_APP}?startapp=${btoa(
      JSON.stringify(startParams),
    )}`;

    analytics.track(StacksAnalytics.COPY_LINK, { stackId });

    try {
      await navigator.clipboard.writeText(link);
      getWebApp().HapticFeedback.notificationOccurred('success');
      toast.toast({
        description: t('all:stacks.share.copy_link.success'),
      });
    } catch (e) {
      toast.toast({
        description: t('all:stacks.share.copy_link.error'),
      });
    }
  };

  return {
    copyStackLink,
  };
}
