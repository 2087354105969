import { useTranslation } from 'react-i18next';

import { GlobeIcon, ShevronRightIcon } from '@tg-web/icons';

import { TimezonesModal } from '../../features/events/ui/TimezonesModal';
import { formatTimeZone } from '../lib/formatTimeZone';
import { ViewField } from './ViewField';

export interface TimezoneFieldProps {
  onChange: (value: string) => void;
  onClose: VoidFunction;
  onOpen: VoidFunction;
  open: boolean;
  value: string;
}

export function TimezoneField({
  onChange,
  onClose,
  onOpen,
  open,
  value,
}: TimezoneFieldProps) {
  const { t } = useTranslation();
  const { city, offset } = formatTimeZone(value);

  return (
    <>
      <ViewField
        leftIcon={<GlobeIcon className="text-tg-hint" />}
        onClick={onOpen}
        rightIcon={<ShevronRightIcon className="text-tg-hint" />}
      >
        {city}{' '}
        {value === Intl.DateTimeFormat().resolvedOptions().timeZone
          ? `(${t('all:event.timezone.your_timezone')})`
          : offset}
      </ViewField>
      <TimezonesModal
        initial={value}
        onClose={onClose}
        onSave={onChange}
        open={open}
      />
    </>
  );
}
