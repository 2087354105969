import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format as baseFormat } from 'date-fns';

import {
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@tg-web/components';

import { useShowSlot } from '../../../shared/api';
import { formatTimeZone } from '../../../shared/lib/formatTimeZone';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { WeekDays } from '../model';
import { EventsList } from './EventsList';
import { OverlapDot } from './OverlapDot';
import { SlotsList } from './SlotsList';

enum TabsValue {
  schedule = 'schedule',
  slots = 'slots',
}

export interface SlotBookingProps {
  date: Date;
  isLoading: boolean;
  onSubmitBooking: (selectedStartTime: string, duration: number) => void;
  slotId: string;
}

export function SlotBooking({
  date,
  isLoading,
  onSubmitBooking,
  slotId,
}: SlotBookingProps) {
  const { t } = useTranslation();
  const { format } = useLocalisedDateFormat();
  const { city } = formatTimeZone(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [selectedStartTime, setSelectedStartTime] = useState<
    string | undefined
  >();
  const [currentTab, setCurrentTab] = useState(TabsValue.slots);

  const slotReq = useShowSlot({
    pathParams: { id: slotId },
    queryParams: { date: format(date, 'yyyy-MM-dd') },
  });

  const slots = slotReq.isSuccess
    ? slotReq.data.slot.free_slots[
        baseFormat(date, 'EEEE').toLowerCase() as WeekDays
      ]
    : null;
  const isOverlapExist = useMemo(() => {
    if (!slotReq.data?.slot.has_calendar) {
      return false;
    }
    return slots?.some((slot) => slot.overlaps);
  }, [slotReq.data?.slot.has_calendar]);

  useEffect(() => {
    setSelectedStartTime(undefined);
    setCurrentTab(TabsValue.slots);
  }, [date]);

  useMainButton({
    isHidden: !selectedStartTime,
    isLoading,
    onClick: () => {
      if (selectedStartTime && slotReq.isSuccess) {
        onSubmitBooking(selectedStartTime, slotReq.data.slot.slot_size);
      }
    },
    text: selectedStartTime
      ? `${t('all:slots.view.confirm')} ${format(selectedStartTime, 'd MMM, HH:mm')}`
      : '',
  });

  if (slotReq.isPending) {
    return (
      <div className="flex flex-col gap-3">
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
        <Skeleton className="bg-tg-section-bg h-14 w-full rounded-2xl" />
      </div>
    );
  }

  if (slotReq.isSuccess && slots && slots.length > 0) {
    return (
      <div className="border-t-03 border-tg-section-separator">
        <div className="py-5">
          <p className="typo-text-bold capitalize">
            {format(date, 'EEEE, d MMMM yyy')}
          </p>
          <p>
            {city
              ? `${city} (${t('all:slots.view.your_timezone')})`
              : t('all:slots.view.your_timezone')}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          {slotReq.data.slot.has_calendar && (
            <Tabs
              className="flex flex-col gap-3"
              onValueChange={(tab) => setCurrentTab(tab as TabsValue)}
              value={currentTab}
            >
              <TabsList
                className="border-03 border-tg-section-separator"
                size="big"
                variant="primary"
              >
                <TabsTrigger
                  currentValue={currentTab}
                  size="big"
                  value={TabsValue.slots}
                  variant="primary"
                >
                  {t('all:slots.view.tabs.time')}
                </TabsTrigger>
                <TabsTrigger
                  currentValue={currentTab}
                  size="big"
                  value={TabsValue.schedule}
                  variant="primary"
                >
                  {t('all:slots.view.tabs.events')}
                </TabsTrigger>
              </TabsList>
              <TabsContent
                className="flex flex-col gap-3"
                value={TabsValue.slots}
              >
                {isOverlapExist && (
                  <div className="flex items-center justify-center gap-3">
                    <OverlapDot />
                    <span className="typo-text text-tg-hint">
                      {t('all:slots.view.overlap')}
                    </span>
                  </div>
                )}
                {slots && (
                  <SlotsList
                    onClick={setSelectedStartTime}
                    selectedStartTime={selectedStartTime}
                    slots={slots}
                  />
                )}
              </TabsContent>
              <TabsContent
                className="flex flex-col gap-3"
                value={TabsValue.schedule}
              >
                <EventsList date={date} />
              </TabsContent>
            </Tabs>
          )}
          {!slotReq.data?.slot.has_calendar && slots && (
            <SlotsList
              onClick={setSelectedStartTime}
              selectedStartTime={selectedStartTime}
              slots={slots}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
}
