import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Cross2Icon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { Button, Switch } from '@tg-web/components';
import {
  ChainIcon,
  CheckmarkIcon,
  MenuIcon,
  ShareSecondaryIcon,
  ShevronRightIcon,
} from '@tg-web/icons';
import { cn, useBoolean } from '@tg-web/utils';

import {
  OwnSlot,
  useDisableOwnSlot,
  useEnableOwnSlot,
  useUserProfile,
} from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { removeCloudStorageItem } from '../../../shared/lib/removeCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { ViewField } from '../../../shared/ui/ViewField';
import { UserAvatar, formatFullName } from '../../user';
import { useShareSlots } from '../lib';
import { SlotsInfo } from './SlotsInfo';

export interface SlotsMainProps {
  slot: OwnSlot;
}

export function SlotsMain({ slot }: SlotsMainProps) {
  const { t } = useTranslation();
  const userProfile = useUserProfile({});
  const enableSlotReq = useEnableOwnSlot();
  const disableSlotReq = useDisableOwnSlot();
  const { shareSlots } = useShareSlots();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showReadyBadge, setShowReadyBadge] = useBoolean(false);

  const handleSwitchClick = () => {
    if (slot.state === 'enabled') {
      disableSlotReq.mutate({});
    } else {
      enableSlotReq.mutate({});
    }
  };
  const handleSettingsClick = () => {
    navigate({ to: '/slots/settings' });
  };
  const handleCloseBadgeClick = () => {
    setShowReadyBadge.off();
    removeCloudStorageItem(CloudStorageKeys.SHOW_SLOTS_READY_BADGE);
  };

  useEffect(() => {
    if (enableSlotReq.isSuccess) {
      queryClient.setQueryData(['slot'], enableSlotReq.data);
    }
  }, [enableSlotReq.isSuccess]);
  useEffect(() => {
    if (disableSlotReq.isSuccess) {
      queryClient.setQueryData(['slot'], disableSlotReq.data);
    }
  }, [disableSlotReq.isSuccess]);
  useEffect(() => {
    getCloudStorageItem(CloudStorageKeys.SHOW_SLOTS_READY_BADGE).then(
      (value) => {
        if (value) {
          setShowReadyBadge.on();
        }
      },
    );
  }, []);

  if (userProfile.isPending) {
    return <GlobalLoading />;
  }
  const profile = userProfile.data?.user.data;
  const isSlotEnabled = slot.state === 'enabled';

  return (
    <div className="flex flex-col gap-3">
      {showReadyBadge && (
        <div className="bg-tg-section-bg relative flex flex-col items-center rounded-2xl px-9 py-6">
          <Cross2Icon
            className="text-tg-subtitle-text absolute right-4 top-4 h-6 w-6 cursor-pointer"
            onClick={handleCloseBadgeClick}
          />
          <div className="bg-tg-button flex h-11 w-11 items-center justify-center rounded-full">
            <CheckmarkIcon
              className="text-tg-button-text"
              height={22}
              width={22}
            />
          </div>
          <h1 className="typo-header-big mt-3">
            {t('all:slots.main.ready.title')}
          </h1>
          <p className="typo-text mt-2 text-center">
            {t('all:slots.main.ready.text')}
          </p>
        </div>
      )}
      {profile && (
        <div className="bg-tg-section-bg flex flex-col items-center rounded-2xl p-8">
          <SlotsInfo profile={profile} slotSize={slot.slot_size} />
          <Button className="mt-4" onClick={() => shareSlots(slot)} size="tiny">
            <ShareSecondaryIcon />
            {t('all:slots.main.share_link')}
          </Button>
        </div>
      )}
      <div>
        <ViewField
          className="cursor-pointer"
          innerClassName="pl-12"
          leftIcon={<MenuIcon className="text-tg-hint" />}
          onClick={handleSettingsClick}
          rightIcon={<ShevronRightIcon className="text-tg-hint" />}
        >
          <span className="typo-text">{t('all:slots.main.settings')}</span>
        </ViewField>
      </div>
      <div className="bg-tg-section-bg flex flex-col gap-3 rounded-2xl p-3 pb-4">
        <div className="flex items-center gap-4">
          <ChainIcon className="text-tg-hint" />
          <span className={cn({ 'text-tg-destructive-text': !isSlotEnabled })}>
            {t(
              isSlotEnabled
                ? 'all:slots.main.link.on.label'
                : 'all:slots.main.link.off.label',
            )}
          </span>
          <Switch
            checked={isSlotEnabled}
            className="ml-auto"
            disabled={enableSlotReq.isPending || disableSlotReq.isPending}
            onClick={handleSwitchClick}
          />
        </div>
        <div className="typo-text text-tg-hint ml-12">
          {t(
            isSlotEnabled
              ? 'all:slots.main.link.on.description'
              : 'all:slots.main.link.off.description',
          )}
        </div>
      </div>
    </div>
  );
}
