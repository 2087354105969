import { useCallback, useEffect, useRef } from 'react';

import { useBoolean } from '@tg-web/utils';

import { useUserProfileConfigured } from '../../../shared/api';

export function useProfilePolling() {
  const [isConnectingGoogle, setIsConnectingGoogle] = useBoolean(false);
  const [hasProcessingAccounts, setHasProcessingAccounts] = useBoolean(false);

  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const clearCurrentTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  useEffect(() => {
    return clearCurrentTimeout;
  }, [clearCurrentTimeout]);

  const userProfile = useUserProfileConfigured({
    refetchInterval: isConnectingGoogle || hasProcessingAccounts ? 3000 : 0,
    refetchOnWindowFocus: 'always',
  });

  const handleStartConnecting = () => {
    clearCurrentTimeout();

    setIsConnectingGoogle.on();

    timeoutIdRef.current = setTimeout(
      () => {
        setIsConnectingGoogle.off();
      },
      5 * 60 * 1000,
    );
  };

  useEffect(() => {
    if (userProfile.data?.user.data.attributes.has_processing_accounts) {
      setHasProcessingAccounts.on();
    } else {
      setHasProcessingAccounts.off();
    }
  }, [
    setHasProcessingAccounts,
    userProfile.data?.user.data.attributes.has_processing_accounts,
  ]);

  const hasGoogleCalendar =
    userProfile.data?.user.data.attributes.has_google_calendar ?? false;
  useEffect(() => {
    if (userProfile.isSuccess && hasGoogleCalendar) {
      setIsConnectingGoogle.off();
    }
  }, [setIsConnectingGoogle, userProfile.isSuccess, hasGoogleCalendar]);

  return {
    handleStartConnecting,
    hasGoogleCalendar,
    hasProcessingAccounts,
    userProfile,
  };
}
