import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { RemoveIcon } from '@tg-web/icons';

import { Event } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { useDeleteEventLogic } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface DeleteEventButtonProps {
  event: Event;
  fromFeed: boolean;
  text?: string;
}

export function DeleteEventButton({ event, fromFeed, text }: DeleteEventButtonProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const { handleDelete, isPending } = useDeleteEventLogic({ event, fromFeed });

  const handleClick = () => {
    analytics.track(EventsAnalytics.DELETE, { eventId: event.id });

    handleDelete();
  };

  return (
    <EventActionButton
      disabled={isPending}
      icon={<RemoveIcon />}
      onClick={handleClick}
      text={text ?? t('all:common.buttons.remove')}
    />
  );
}
