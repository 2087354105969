import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { RemoveIcon } from '@tg-web/icons';
import { useCallbackRef } from '@tg-web/utils';

import { Event, useMissEvent } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { RecurrentType } from '../../recurrency';
import { useDeletePrompt } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface NotInterestedEventButtonProps {
  event: Event;
  isAttend?: boolean;
  onSuccess?: () => void;
  skipChangeAttention?: boolean;
  text?: string;
}

export function NotInterestedEventButton({
  event,
  isAttend,
  onSuccess,
  skipChangeAttention,
  text,
}: NotInterestedEventButtonProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const onSuccessCallbackRef = useCallbackRef(onSuccess);
  const deletePrompt = useDeletePrompt(true);
  const missEvent = useMissEvent();

  useEffect(() => {
    if (missEvent.isSuccess) {
      onSuccessCallbackRef();
    }
  }, [missEvent.isSuccess, onSuccessCallbackRef]);

  // TODO: handle error

  const handleClick = async () => {
    analytics.track(EventsAnalytics.NOT_INTERESTED, { eventId: event.id });

    if (skipChangeAttention) {
      onSuccessCallbackRef();
    } else {
      let dependentMiss = 0;

      if (event.attributes.recurrent_type !== RecurrentType.noRecurrency) {
        const promptResult = await deletePrompt();
        if (promptResult === 'close' || promptResult === 'discard') {
          return;
        }
        if (promptResult === 'secondary') {
          dependentMiss = 1;
        }
      }

      missEvent.mutate({
        body: { dependent_miss: dependentMiss },
        pathParams: { id: event.id },
      });
    }
  };

  return (
    <EventActionButton
      text={
        text ??
        t(
          isAttend
            ? 'all:common.buttons.remove'
            : 'all:common.buttons.not_interested',
        )
      }
      disabled={missEvent.isPending}
      icon={<RemoveIcon />}
      onClick={handleClick}
    />
  );
}
