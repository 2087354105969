import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { QuestionMarkIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { useAttendEventHandler } from '../lib';
import { AttendStatus, AttendStatusesRequiringOverlap } from '../model';
import { EventActionButton } from './EventActionButton';
import { OverlapEvents } from './OverlapEvents';

export interface MaybeEventButtonProps {
  event: Event;
  onSuccess?: () => void;
  text?: string;
}

export function MaybeEventButton({
  event,
  onSuccess,
  text,
}: MaybeEventButtonProps) {
  const overlapModal = useDisclosure();
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const [handleClick, { isPending }] = useAttendEventHandler(
    event,
    AttendStatus.maybe,
    onSuccess,
  );

  const handleButtonClick = () => {
    analytics.track(EventsAnalytics.MAYBE, { eventId: event.id });

    if (
      AttendStatusesRequiringOverlap.includes(event.attributes.attend_status)
    ) {
      overlapModal.onOpen();
    } else {
      handleClick();
    }
  };

  return (
    <>
      <EventActionButton
        disabled={isPending}
        icon={<QuestionMarkIcon />}
        onClick={handleButtonClick}
        text={text ?? t('all:common.buttons.maybe')}
      />
      <OverlapEvents
        duration={event.attributes.duration.toString()}
        enabled={overlapModal.open}
        onCancel={overlapModal.onClose}
        onContinue={handleClick}
        start_at={event.attributes.start_at}
      />
    </>
  );
}
