import { getWebApp } from '@tg-web/utils';

import { CloudStorageKeys } from '../model/cloudStorageKeys';

export function setCloudStorageItem(
  key: keyof typeof CloudStorageKeys,
  value: string,
) {
  return new Promise<string | null>((resolve, reject) => {
    getWebApp().CloudStorage.setItem(key, value, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    });
  });
}
