import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { EditEventPage } from '../../../../pages/events/ui/EditEventPage';

const eventEditSearchSchema = z.object({
  fromFeed: z.boolean(),
});

export const Route = createFileRoute('/_authorized/events/$eventId/edit')({
  component: Page,
  validateSearch: zodSearchValidator(eventEditSearchSchema),
});

function Page() {
  const { eventId } = Route.useParams();
  const { fromFeed } = Route.useSearch();

  return <EditEventPage eventId={eventId} fromFeed={fromFeed} />;
}
