import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Form,
  FormControl,
  FormField,
  Separator,
} from '@tg-web/components';
import { useDisclosure } from '@tg-web/utils';

import { CreateSlotRequestBody, OwnSlot } from '../../../shared/api';
import { useMainButton } from '../../../shared/lib/useMainButton';
import { TimezoneField } from '../../../shared/ui/TimezoneField';
import { mapSlotFormValueToItems } from '../lib';
import { mapSlotItemsToFormValue } from '../lib/mapSlotItemsToFormValue';
import { weekScheduleSchema } from '../model';
import { defaultWeekSchedule } from '../model/slotFormValue';
import { ScheduleField } from './ScheduleField';
import { SlotSizeSelect } from './SlotSizeSelect';

const formSchema = z.object({
  schedule: weekScheduleSchema,
  slotSize: z.string(),
  timezone: z.string(),
});

type SlotsForm = z.infer<typeof formSchema>;

export interface SlotsFormProps {
  defaultValues?: OwnSlot;
  isLoading: boolean;
  onModalStateChange: (open: boolean) => void;
  onSubmit: (values: CreateSlotRequestBody) => void;
  submitButtonText: string;
}

export function SlotsForm({
  defaultValues,
  isLoading,
  onModalStateChange,
  onSubmit,
  submitButtonText,
}: SlotsFormProps) {
  const { t } = useTranslation();
  const timezonesModal = useDisclosure();
  const errorAlert = useDisclosure();

  const form = useForm<SlotsForm>({
    defaultValues: {
      schedule: defaultValues
        ? mapSlotItemsToFormValue(defaultValues.slot_items)
        : defaultWeekSchedule,
      slotSize: defaultValues?.slot_size.toString() ?? '60',
      timezone:
        defaultValues?.time_zone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = form.handleSubmit(
    (values) => {
      onSubmit({
        schedule: mapSlotFormValueToItems(values.schedule),
        slot_size: Number(values.slotSize),
        time_zone: values.timezone,
      });
    },
    () => {
      errorAlert.onOpen();
    },
  );

  const handleModalOpen = () => {
    onModalStateChange(true);
    timezonesModal.onOpen();
  };

  const handleModalClose = () => {
    onModalStateChange(false);
    timezonesModal.onClose();
  };

  useMainButton({
    isHidden: timezonesModal.open,
    isLoading,
    onClick: handleSubmit,
    text: submitButtonText,
  });

  return (
    <>
      <Form {...form}>
        <form
          className="flex flex-col justify-between gap-3"
          onSubmit={handleSubmit}
        >
          <div>
            <FormField
              render={({ field }) => (
                <FormControl>
                  <SlotSizeSelect
                    onValueChange={field.onChange}
                    value={field.value}
                  />
                </FormControl>
              )}
              control={form.control}
              name="slotSize"
            />
            <FormField
              render={({ field }) => {
                return (
                  <FormControl>
                    <TimezoneField
                      onChange={field.onChange}
                      onClose={handleModalClose}
                      onOpen={handleModalOpen}
                      open={timezonesModal.open}
                      value={field.value}
                    />
                  </FormControl>
                );
              }}
              control={form.control}
              name="timezone"
            />
          </div>

          <FormField
            render={({ field, fieldState }) => (
              <FormControl>
                <ScheduleField
                  error={fieldState.error}
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
            )}
            control={form.control}
            name="schedule"
          />
        </form>
      </Form>
      <AlertDialog open={errorAlert.open}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('all:slots.form.schedule.error_title')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t('all:slots.form.schedule.error_text')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <Separator />
          <AlertDialogFooter>
            <Button
              className="text-tg-link typo-text"
              onClick={() => errorAlert.onClose()}
              size="small"
              variant="ghost"
            >
              {t('all:common.buttons.ok')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
