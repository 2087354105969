import { getWebApp } from '@tg-web/utils';

import {
  DefaultTheme,
  DefaultThemeType,
  getTgTheme,
} from '../model/defaultTheme';

export const setAppBackground = (themeOverride: Partial<DefaultThemeType>) => {
  const theme: DefaultThemeType = { ...DefaultTheme, ...themeOverride };

  // set style of body to fix bg bug in web and desktop telegram
  if (theme.backgroundColor === 'bg_color') {
    document.body.style.backgroundColor = getTgTheme().bg_color;
  } else if (theme.backgroundColor === 'secondary_bg_color') {
    document.body.style.backgroundColor = getTgTheme().secondary_bg_color;
  } else {
    document.body.style.backgroundColor = theme.backgroundColor;
  }

  getWebApp().setBackgroundColor(theme.backgroundColor);
  getWebApp().setHeaderColor(theme.headerColor);
  getWebApp().setBottomBarColor(theme.bottomBarColor);
};
