import { getWebApp } from '@tg-web/utils';

export const tgLightTheme: Required<ThemeParams> = {
  accent_text_color: getWebApp().themeParams.accent_text_color ?? '#2CB9ED',
  bg_color: '#ffffff',
  bottom_bar_bg_color: '#ffffff',
  button_color: getWebApp().themeParams.button_color ?? '#2481CC',
  button_text_color: '#ffffff',
  destructive_text_color: '#FF3B30',
  header_bg_color: '#F8F8F8',
  hint_color: '#8E8E93',
  link_color: getWebApp().themeParams.link_color ?? '#2CB9ED',
  secondary_bg_color: '#EFEFF4',
  section_bg_color: '#ffffff',
  section_header_text_color: '#6D6D72',
  section_separator_color: '#C8C7CC',
  subtitle_text_color: '#8E8E93',
  text_color: '#000000',
};

export const tgDarkTheme: Required<ThemeParams> = {
  accent_text_color: getWebApp().themeParams.accent_text_color ?? '#3E88F7',
  bg_color: '#000000',
  bottom_bar_bg_color: '#2C2C2E',
  button_color: getWebApp().themeParams.button_color ?? '#3E88F7',
  button_text_color: '#ffffff',
  destructive_text_color: '#EB5545',
  header_bg_color: '#1A1A1A',
  hint_color: '#98989E',
  link_color: getWebApp().themeParams.link_color ?? '#3E88F7',
  secondary_bg_color: '#1C1C1D',
  section_bg_color: '#2C2C2E',
  section_header_text_color: '#8D8E93',
  section_separator_color: '#545458',
  subtitle_text_color: '#98989E',
  text_color: '#ffffff',
};

export function getTgTheme() {
  return getWebApp().colorScheme === 'dark' ? tgDarkTheme : tgLightTheme;
}

export type DefaultThemeType = {
  backgroundColor: Parameters<WebApp['setBackgroundColor']>[0];
  bottomBarColor: Parameters<WebApp['setBottomBarColor']>[0];
  headerColor: Parameters<WebApp['setHeaderColor']>[0];
};

export const DefaultTheme: DefaultThemeType = {
  backgroundColor: getTgTheme().secondary_bg_color ?? 'secondary_bg_color',
  bottomBarColor: getTgTheme().bottom_bar_bg_color ?? 'secondary_bg_color',
  headerColor: getTgTheme().secondary_bg_color ?? 'secondary_bg_color',
};
