import { Time } from '@internationalized/date';

import { OwnSlot } from '../../../shared/api';
import { WeekDays } from '../model';

export function mapSlotItemsToFormValue(slotItems: OwnSlot['slot_items']) {
  const formValue: Partial<
    Record<WeekDays, Array<{ endTime: Time; startTime: Time }>>
  > = {};
  Object.entries(slotItems).forEach(([key, items]) => {
    formValue[key as WeekDays] = items.map(({ end_time, start_time }) => {
      const endTime = end_time.split(':');
      const startTime = start_time.split(':');
      return {
        endTime: new Time(Number(endTime[0]), Number(endTime[1])),
        startTime: new Time(Number(startTime[0]), Number(startTime[1])),
      };
    });
  });
  return formValue;
}
