import type { NormalizedInterval } from 'date-fns/types';

import { differenceInMinutes, isSameMinute, startOfDay } from 'date-fns';

import { MINUTES_IN_DAY } from '../model';

export function isFullDayInterval(interval: NormalizedInterval) {
  return (
    isSameMinute(interval.start, startOfDay(interval.start)) &&
    differenceInMinutes(interval.end, interval.start) === MINUTES_IN_DAY
  );
}
