import { useTranslation } from 'react-i18next';

import { Stepper } from '../../../shared/ui/Stepper';

export function SlotsGooglePageHeader() {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="typo-header-big">{t('all:slots.google.header.title')}</h1>
      <p className="mt-2">{t('all:slots.google.header.description')}</p>
      <Stepper activeIndex={1} className="mt-5" count={2} />
    </div>
  );
}
