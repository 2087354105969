import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileRoutesByPath, useNavigate } from '@tanstack/react-router';
import { motion } from 'framer-motion';

import { Tabs, TabsList, TabsTrigger } from '@tg-web/components';
import { getWebApp, useBoolean } from '@tg-web/utils';

import { NewLabel } from '../../../features/stacks/ui/NewLabel';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { useSafeAreaInset } from '../../../shared/lib/useSafeAreaInset';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

type TabsRoutes =
  | FileRoutesByPath['/_authorized/_withTabs/feed']['path']
  | FileRoutesByPath['/_authorized/_withTabs/slots']['path']
  | FileRoutesByPath['/_authorized/_withTabs/stacks']['path'];

export enum TabsValues {
  feed = 'feed',
  slots = 'slots',
  stacks = 'stacks',
}

const tabs = [
  { label: 'all:stacks.main_tabs.feed', value: TabsValues.feed },
  { label: 'all:stacks.main_tabs.stacks', value: TabsValues.stacks },
  { label: 'all:stacks.main_tabs.slots', value: TabsValues.slots },
] as const;

export const tabsToRoutesMap = new Map<TabsValues, TabsRoutes>([
  [TabsValues.feed, '/feed'],
  [TabsValues.slots, '/slots'],
  [TabsValues.stacks, '/stacks'],
]);

export interface MainTabsProps {
  initialTab: TabsValues;
}

export function MainTabs({ initialTab }: MainTabsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(initialTab);
  const [showNewLabel, setShowNewLabel] = useBoolean(false);
  const safeAreaInset = useSafeAreaInset();

  const handleTabChange = (tab: TabsValues) => {
    setCurrentTab(tab);
    navigate({ to: tabsToRoutesMap.get(tab) });
    if (showNewLabel && tab === TabsValues.slots) {
      setShowNewLabel.off();
      getWebApp().CloudStorage.setItem(
        CloudStorageKeys.HIDE_SLOTS_NEW_LABEL,
        'true',
      );
    }
  };

  useEffect(() => {
    getCloudStorageItem(CloudStorageKeys.HIDE_SLOTS_NEW_LABEL).then((value) => {
      if (!value) {
        setShowNewLabel.on();
      }
    });
  }, []);

  return (
    <div
      style={{
        paddingBottom: safeAreaInset.bottom > 0 ? safeAreaInset.bottom : 8,
      }}
      className="bg-tg-bg shadow-main-tabs fixed bottom-0 z-10 min-h-[50px] w-full px-5 pt-2"
    >
      <Tabs
        onValueChange={(value) => handleTabChange(value as TabsValues)}
        value={currentTab}
      >
        <TabsList variant="primary">
          {tabs.map((tab) => (
            <TabsTrigger
              currentValue={currentTab}
              key={tab.value}
              value={tab.value}
              variant="primary"
            >
              {t(tab.label)}{' '}
              {tab.value === TabsValues.slots && showNewLabel && <NewLabel />}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
    </div>
  );
}
