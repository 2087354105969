import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@tanstack/react-router';

import { useBackNavigation } from '@tg-web/utils';

import { AttendStatus } from '../../../features/events';
import {
  DeleteParticipantModal,
  ParticipantCard,
} from '../../../features/participants';
import {
  Invite,
  User,
  useShowEvent,
  useUserProfileConfigured,
} from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { sortParticipants } from '../lib/sortParticipants';

export interface EventParticipantsPageProps {
  eventId: string;
  fromFeed: boolean;
}

export function EventParticipantsPage({
  eventId,
  fromFeed,
}: EventParticipantsPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userProfile = useUserProfileConfigured();

  const event = useShowEvent({
    pathParams: { id: eventId },
  });

  useBackNavigation(() => {
    navigate({
      params: { eventId },
      search: { fromFeed },
      to: '/events/$eventId/view',
    });
  });

  const included = event.data?.event.included ?? [];
  const invitesData = event.data?.event.data.relationships.invites.data ?? [];

  const eventData = event.data?.event.data;

  const eventCreator = included
    .filter((it) => it.type === 'user')
    .find((it) => it.id === eventData?.relationships.user.data.id)! as User;

  const participants = useMemo(
    () =>
      sortParticipants(
        invitesData.map((invitee) => {
          const inviteeData = included.find(
            (it) => it.type === invitee.type && it.id === invitee.id,
          )! as Invite;

          const user = included.find(
            (it) =>
              it.type === inviteeData.relationships.user.data.type &&
              it.id === inviteeData.relationships.user.data.id,
          )! as User;

          return {
            id: inviteeData.id,
            status: inviteeData.attributes.status as AttendStatus,
            user,
          };
        }),
        eventCreator.id,
      ),
    [invitesData, eventCreator.id, included],
  );

  const [userForDelete, setUserForDelete] = useState<
    (typeof participants)[number] | undefined
  >(undefined);

  if (
    event.isFetching ||
    event.isPending ||
    userProfile.isFetching ||
    userProfile.isPending
  ) {
    return <GlobalLoading />;
  }

  if (event.isError || userProfile.isError) {
    throw new Error('Unexpected API error');
  }

  const isCreator = eventCreator.id === userProfile.data.user.data.id;

  return (
    <PageWrapper className="flex flex-col gap-6 p-5">
      <h1 className="typo-header-big">
        {t('all:event.participants.page.title')}
      </h1>
      <div>
        {/* TODO: infinite list */}
        {participants
          .filter((it) => it.status !== AttendStatus.removed)
          .map((it) => (
            <ParticipantCard
              onRemove={
                isCreator && it.user.id !== eventCreator.id
                  ? () => {
                      setUserForDelete(it);
                    }
                  : undefined
              }
              eventCreatorId={eventCreator.id}
              key={it.id}
              status={it.status}
              user={it.user}
            />
          ))}
      </div>
      <DeleteParticipantModal
        onClose={() => {
          setUserForDelete(undefined);
        }}
        onSuccess={() => {
          event.refetch();
          setUserForDelete(undefined);
        }}
        eventId={eventId}
        open={userForDelete !== undefined}
        status={userForDelete?.status}
        user={userForDelete?.user}
      />
    </PageWrapper>
  );
}
