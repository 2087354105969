import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer, { Size as AutoSizerSize } from 'react-virtualized-auto-sizer';
import { ListOnItemsRenderedProps, VariableSizeList } from 'react-window';

import { AccordionHeader } from '@radix-ui/react-accordion';
import { useNavigate } from '@tanstack/react-router';
import {
  addDays,
  addMonths,
  addYears,
  eachDayOfInterval,
  endOfMonth,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
} from '@tg-web/components';
import { DateIcon } from '@tg-web/icons';

import {
  DayBadge,
  Events,
  FeedHeader,
  MonthsCarousel,
} from '../../../features/feed';
import { useEventsGroupedByDay } from '../../../features/feed/lib/useEventsGroupedByDay';
import {
  ListEventVariables,
  useGoogleCalendarAccountConfigured,
  useInfiniteListEvent,
  useUserProfileConfigured,
} from '../../../shared/api';
import { setAppBackground } from '../../../shared/lib/setAppBackground';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { useSafeAreaInset } from '../../../shared/lib/useSafeAreaInset';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

const EVENT_HEIGHT = 54;
const MONTH_TITLE_HEIGHT = 54;

export type FeedProps = {
  initialDate?: Date;
};
export function Feed({ initialDate: outerInitialDate }: FeedProps) {
  const { t } = useTranslation();
  const { format } = useLocalisedDateFormat();
  const navigate = useNavigate();

  const eventListContainerRef = useRef<HTMLDivElement | null>(null);
  const eventListRef = useRef<VariableSizeList | null>(null);
  const initialScrollPerformedRef = useRef(false);
  const [headerAccordionValue, setHeaderAccordionValue] = useState('');
  const [initialDate, setInitialDate] = useState(
    outerInitialDate ?? new Date(),
  );
  const firstVisibleDayRef = useRef(initialDate);
  const [selectedDay, setSelectedDay] = useState<Date>(initialDate);
  const [autoSizerSize, setAutoSizerSize] = useState<AutoSizerSize | undefined>(
    undefined,
  );
  const debouncedSetSelectedDay = useDebouncedCallback(setSelectedDay, 10);
  const safeAreaInset = useSafeAreaInset();

  const userProfile = useUserProfileConfigured();
  const hasGoogleCalendar =
    userProfile.isSuccess &&
    userProfile.data.user.data.attributes.has_google_calendar;
  const { googleAccount, googleAccountsList } =
    useGoogleCalendarAccountConfigured({
      enabled: hasGoogleCalendar,
    });

  useEffect(() => {
    setAppBackground({ backgroundColor: 'bg_color' });

    return () => {
      setAppBackground({});
    };
  }, []);

  const eventDays = useMemo(() => {
    const startOfInitialDate = startOfDay(initialDate);

    return eachDayOfInterval({
      end: addYears(startOfInitialDate, 2),
      start: subYears(startOfInitialDate, 2),
    });
  }, [initialDate]);

  const initialPageParam = useMemo(() => {
    const startOfInitialDate = startOfDay(initialDate);

    return {
      queryParams: {
        from: subMonths(startOfMonth(startOfInitialDate), 1).toISOString(),
        to: addMonths(endOfMonth(startOfInitialDate), 1).toISOString(),
      },
    };
  }, [initialDate]);

  const listEvent = useInfiniteListEvent(initialPageParam, {
    getNextPageParam: (_, __, params) => {
      const lastDay = params.queryParams!.to!;

      return {
        queryParams: {
          from: addDays(lastDay, 1).toISOString(),
          to: addMonths(lastDay, 1).toISOString(),
        },
      };
    },
    getPreviousPageParam: (_, __, params) => {
      const firstDay = params.queryParams!.from!;

      return {
        queryParams: {
          from: subMonths(firstDay, 1).toISOString(),
          to: subDays(firstDay, 1).toISOString(),
        },
      };
    },
    initialPageParam,
  });

  const pageParams = (listEvent.data?.pageParams as ListEventVariables[]) ?? [
    initialPageParam,
  ];
  const firstLoadedDate = parseISO(pageParams[0].queryParams!.from!);
  const lastLoadedDate = parseISO(
    pageParams[pageParams.length - 1].queryParams!.to!,
  );

  const allEvents = useMemo(
    () => listEvent.data?.pages.flatMap((it) => it.events.data),
    [listEvent.data?.pages],
  );
  const groupByDay = useEventsGroupedByDay(allEvents);
  const daysWithEvents = useMemo(() => {
    return Object.entries(groupByDay)
      .filter(([, value]) => Boolean(value?.length))
      .map(([day]) => parseISO(day));
  }, [groupByDay]);

  const scrollEventListToDate = useCallback(
    (day: Date, isSmooth = false) => {
      if (isSmooth && eventListContainerRef.current?.style) {
        eventListContainerRef.current.style.scrollBehavior = 'smooth';
      }
      eventListRef.current?.scrollToItem(
        eventDays.findIndex((it) => isSameDay(it, day)),
        'start',
      );
      setTimeout(() => {
        if (isSmooth && eventListContainerRef.current?.style) {
          eventListContainerRef.current.style.scrollBehavior = 'auto';
        }
      }, 200);
    },
    [eventDays],
  );

  // Initial scroll
  useEffect(() => {
    // to prevent call on uninitialized eventListRef
    if (
      userProfile.isSuccess &&
      (!hasGoogleCalendar || googleAccount.isSuccess)
    ) {
      setTimeout(() => {
        scrollEventListToDate(selectedDay);
        initialScrollPerformedRef.current = true;
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasGoogleCalendar,
    googleAccount.isSuccess,
    scrollEventListToDate,
    userProfile.isSuccess,
  ]);

  // recalculate items height after every fetching
  const isFetchingRef = useRef(false);
  useEffect(() => {
    if (isFetchingRef.current && !listEvent.isFetching) {
      isFetchingRef.current = false;
      const firstVisibleDay = firstVisibleDayRef.current;
      eventListRef.current?.resetAfterIndex(
        eventDays.findIndex((it) => isSameDay(it, firstLoadedDate)),
      );
      scrollEventListToDate(firstVisibleDay);
    }
    if (listEvent.isFetching) {
      isFetchingRef.current = true;
    }
  }, [listEvent.isFetching]);

  const handleDaySelect = (day: Date, isSmooth = false) => {
    setSelectedDay(day);
    debouncedSetSelectedDay.cancel();
    scrollEventListToDate(day, isSmooth);
  };

  const handleTodayButtonClick = () => {
    const today = new Date();

    // trigger refetch with new initial date if today is not in loaded event dates
    if (
      !isWithinInterval(today, { end: lastLoadedDate, start: firstLoadedDate })
    ) {
      setInitialDate(today);
    }

    handleDaySelect(today, !headerAccordionValue);
  };

  const handleCreateClick = () => {
    navigate({
      search: { date: format(selectedDay, DATE_SEARCH_PARAM_FORMAT) },
      to: '/events/new',
    });
  };

  const handleMonthChange = (date: Date) => {
    const today = new Date();
    handleDaySelect(isSameMonth(date, today) ? today : date);
  };

  const handleResize = (size: AutoSizerSize) => {
    // Sometimes, after closing the calendar, AutoSizer returns the height as if the calendar was still open.
    // Since the calendar opens and closes with animation, the height value changes smoothly,
    // and we can assume that excessively large deviations are an error.
    // We skip height changes greater than 100px.
    setAutoSizerSize((prevSize) =>
      prevSize &&
      initialScrollPerformedRef.current && // fixed navigation from page with opened keyboard
      Math.abs(prevSize.height - size.height) > 100
        ? prevSize
        : size,
    );
  };

  const getEventDayHeight = (index: number) => {
    const dayEvents = groupByDay[eventDays[index].toISOString()];
    const height =
      (dayEvents
        ? (dayEvents.length + 1) * EVENT_HEIGHT + dayEvents.length * 4
        : EVENT_HEIGHT) + 24;
    return isFirstDayOfMonth(eventDays[index])
      ? height + MONTH_TITLE_HEIGHT
      : height;
  };

  const handleEventDayRendered = ({
    overscanStartIndex,
    overscanStopIndex,
    visibleStartIndex,
  }: ListOnItemsRenderedProps) => {
    debouncedSetSelectedDay(eventDays[visibleStartIndex]);

    firstVisibleDayRef.current = eventDays[visibleStartIndex];

    if (initialScrollPerformedRef.current && !listEvent.isFetching) {
      if (isBefore(eventDays[overscanStartIndex], firstLoadedDate)) {
        listEvent.fetchPreviousPage();
      }

      if (isAfter(eventDays[overscanStopIndex], lastLoadedDate)) {
        listEvent.fetchNextPage();
      }
    }
  };

  // Loading state
  if (
    userProfile.isPending ||
    googleAccountsList.isLoading ||
    googleAccount.isLoading
  ) {
    return <GlobalLoading />;
  }

  if (
    userProfile.isError ||
    googleAccountsList.isError ||
    googleAccount.isError
  ) {
    throw new Error('Unexpected API error');
  }

  return (
    <PageWrapper className="no-scrollbar flex flex-col overflow-y-scroll">
      <div className="bg-tg-secondary-bg border-tg-section-separator border-b-03 sticky top-0 z-[110] shrink-0">
        <Accordion
          onValueChange={setHeaderAccordionValue}
          type="single"
          value={headerAccordionValue}
          collapsible
        >
          <AccordionItem value="item-1">
            <AccordionHeader>
              <FeedHeader
                rightComponent={
                  <Button
                    className="h-[40px] w-[40px] p-0"
                    onClick={handleTodayButtonClick}
                    variant="ghost"
                  >
                    <DateIcon className="h-[32px] w-[32px]" />
                  </Button>
                }
                titleComponent={
                  <AccordionTrigger chevronSize="big" withoutHeader>
                    {format(selectedDay, 'LLLL yyyy')}
                  </AccordionTrigger>
                }
                currentDay={selectedDay}
                onAvatarClick={() => navigate({ to: '/settings' })}
                user={userProfile.data.user.data}
                enableGoogleTooltip
              />
            </AccordionHeader>
            <AccordionContent>
              <MonthsCarousel
                daysWithEvents={daysWithEvents}
                onChangeMonth={handleMonthChange}
                onSelect={handleDaySelect}
                selectedDay={selectedDay}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="bg-tg-bg relative flex grow flex-col gap-7">
        <AutoSizer onResize={handleResize}>
          {({ height, width }) => (
            <VariableSizeList
              className="no-scrollbar"
              height={autoSizerSize?.height ?? height}
              itemCount={eventDays.length}
              itemKey={(index) => eventDays[index].toISOString()}
              itemSize={getEventDayHeight}
              onItemsRendered={handleEventDayRendered}
              outerRef={eventListContainerRef}
              ref={eventListRef}
              width={autoSizerSize?.width ?? width}
            >
              {({ index, style }) => (
                <div style={style}>
                  {isFirstDayOfMonth(eventDays[index]) && (
                    <h2 className="typo-header-small px-4 pb-3 pt-5 capitalize">
                      {format(eventDays[index], 'LLLL yyyy')}
                    </h2>
                  )}
                  <div className="flex flex-row gap-4 px-4 py-3">
                    <DayBadge className="shrink-0" day={eventDays[index]} />
                    <Events
                      googleCalendars={
                        googleAccount.data?.google_calendars.data ?? []
                      }
                      isLoading={
                        listEvent.isLoading ||
                        !isWithinInterval(eventDays[index], {
                          end: lastLoadedDate,
                          start: firstLoadedDate,
                        })
                      }
                      className="grow"
                      day={eventDays[index]}
                      events={groupByDay[eventDays[index].toISOString()] ?? []}
                    />
                  </div>
                </div>
              )}
            </VariableSizeList>
          )}
        </AutoSizer>
        {headerAccordionValue && (
          <div
            onTouchMove={() => {
              setHeaderAccordionValue('');
            }}
            className="absolute bottom-0 left-0 right-0 top-0 bg-transparent"
            onMouseDown={() => setHeaderAccordionValue('')}
          />
        )}
      </div>
      <div
        className="fixed flex w-full justify-center"
        style={{ bottom: safeAreaInset.bottom + 64 }}
      >
        <Button onClick={handleCreateClick} size="small" variant="hover">
          {t('all:feed.new_event')}
        </Button>
      </div>
    </PageWrapper>
  );
}
