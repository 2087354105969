import { useTranslation } from 'react-i18next';

import { SelectProps } from '@radix-ui/react-select';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tg-web/components';
import { ClockIcon, ShevronDownIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { ViewField } from '../../../shared/ui/ViewField';

const slotSizeOptions = ['15', '30', '45', '60'];

export function SlotSizeSelect(props: SelectProps) {
  const { t } = useTranslation();
  const { onOpen, onToggle, open } = useDisclosure();

  return (
    <ViewField
      rightIcon={
        <ShevronDownIcon className={open ? 'text-tg-button' : 'text-tg-hint'} />
      }
      className="cursor-pointer"
      innerClassName="min-w-0 -mr-5 w-full"
      leftIcon={<ClockIcon className="text-tg-hint" />}
      onClick={onOpen}
    >
      <Select onOpenChange={onToggle} open={open} {...props}>
        <SelectTrigger className="typo-text w-full justify-between">
          <span>{t('all:slots.form.size.label')}</span>
          <SelectValue>
            {t('all:slots.form.size.value', { count: Number(props.value) })}
          </SelectValue>
        </SelectTrigger>
        <SelectContent
          onClick={(event) => {
            event.stopPropagation();
          }}
          alignOffset={54}
          sideOffset={15}
        >
          {slotSizeOptions.map((value) => (
            <SelectItem key={value} value={value}>
              {t('all:slots.form.size.option', { count: Number(value) })}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </ViewField>
  );
}
