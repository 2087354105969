import { OwnSlot } from '../../../shared/api';
import { WeekDays, WeekSchedule } from '../model';

/**
 * Converts slot form values to backend-compatible values
 */
export function mapSlotFormValueToItems(
  schedule: WeekSchedule,
): OwnSlot['slot_items'] {
  const slotItems: Partial<OwnSlot['slot_items']> = {};
  Object.entries(schedule).forEach(([key, items]) => {
    slotItems[key as WeekDays] = items?.map(({ endTime, startTime }) => {
      return {
        end_time: endTime.toString().substring(0, 5),
        start_time: startTime.toString().substring(0, 5),
      };
    });
  });
  return slotItems;
}
