import { useJitsu } from '@jitsu/jitsu-react';
import { captureException } from '@sentry/react';
import { Navigate, createFileRoute } from '@tanstack/react-router';

import { getWebApp } from '@tg-web/utils';

import { parseStartappParam } from '../../shared/lib/parseStartappParam';
import { CommonAnalytics } from '../../shared/model/analytics';

export const Route = createFileRoute('/_authorized/')({
  component: IndexPage,
});

function IndexPage() {
  const { analytics } = useJitsu();
  let parsedParams: ReturnType<typeof parseStartappParam> | null = null;

  try {
    const startParam = getWebApp().initDataUnsafe.start_param;
    if (startParam) {
      parsedParams = parseStartappParam(startParam);
    }
  } catch (e) {
    // if start param invalid, just skip redirect
    captureException(e);
  }

  analytics.track(CommonAnalytics.APP_OPEN, {
    parsedStartParams: parsedParams,
    startParams: getWebApp().initDataUnsafe.start_param,
  });

  if (parsedParams) {
    if (parsedParams.mode === 'google_settings') {
      return <Navigate to="/settings/google-calendars" replace />;
    }

    const eventId = parsedParams.token ?? parsedParams.id;
    if (parsedParams.mode === 'view' && eventId) {
      return (
        <Navigate
          params={{ eventId }}
          search={{ fromFeed: false }}
          to="/events/$eventId/view"
          replace
        />
      );
    }

    const stackId = parsedParams.id;
    if (parsedParams.mode === 'viewStack' && stackId) {
      return (
        <Navigate params={{ stackId }} to="/stacks/$stackId/view" replace />
      );
    }

    const slotId = parsedParams.id;
    if (parsedParams.mode === 'viewSlots' && slotId) {
      return <Navigate params={{ slotId }} to="/slots/$slotId/view" replace />;
    }
  }

  return <Navigate to="/feed" replace />;
}
