import { useTranslation } from 'react-i18next';

import { OwnSlot } from '../../../shared/api';
import { openShareDialog } from '../../../shared/lib/openShareDialog';
import { StartParams } from '../../../shared/model/startParamsStore';

export function useShareSlots() {
  const { t } = useTranslation();

  const shareSlots = (slot: OwnSlot) => {
    const startParams: StartParams = {
      id: slot.id,
      mode: 'viewSlots',
    };
    const link = `https://t.me/${import.meta.env.VITE_TG_BOT}/${import.meta.env.VITE_TG_MINI_APP}?startapp=${btoa(
      JSON.stringify(startParams),
    )}`;

    openShareDialog(
      [
        // TODO add message text
      ].join('\n'),
      link,
    );
  };

  return {
    shareSlots,
  };
}
