import { EmptySlots, SlotsMain } from '../../../features/slots';
import { useShowOwnSlot } from '../../../shared/api';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function SlotsPage() {
  const slotReq = useShowOwnSlot(
    {},
    {
      retry: false,
    },
  );

  const isEmpty = slotReq.error?.status === 404;
  if (slotReq.isError && !isEmpty) {
    throw new Error('Unexpected API error');
  }

  if (slotReq.isPending) {
    return <GlobalLoading />;
  }

  return (
    <PageWrapper className="flex flex-col gap-10 px-5 pb-20 pt-3">
      {isEmpty && <EmptySlots />}
      {slotReq.isSuccess && <SlotsMain slot={slotReq.data.slot} />}
    </PageWrapper>
  );
}
