import { createFileRoute } from '@tanstack/react-router';

import { GoogleCalendarOnboardingPage } from '../../pages/google/ui/GoogleCalendarOnboardingPage';

// Temporary not used
export const Route = createFileRoute('/_authorized/google-calendar-onboarding')(
  {
    component: GoogleCalendarOnboardingPage,
  },
);
