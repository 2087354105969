/* eslint-disable @typescript-eslint/no-empty-object-type */
import { captureException } from '@sentry/react';

import { CalendarContext } from './calendarContext';

const baseUrl = import.meta.env.VITE_API_URL;

export type ErrorWrapper<TError> = TError | { payload: string; status: number };

export type CalendarFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams,
  TWithCode,
> = CalendarContext['fetcherOptions'] & {
  body?: TBody;
  headers?: THeaders;
  method: string;
  pathParams?: TPathParams;
  queryParams?: TQueryParams;
  signal?: AbortSignal;
  url: string;
  withCode?: TWithCode;
};

export async function calendarFetch<
  TData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TError,
  TBody extends FormData | {} | null | undefined,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
  TWithCode extends boolean = false,
>({
  body,
  headers,
  method,
  pathParams,
  queryParams,
  signal,
  url,
  withCode,
}: CalendarFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams,
  TWithCode
>): Promise<TWithCode extends true ? { data: TData; status: number } : TData>;
export async function calendarFetch<
  TData,
  TError,
  TBody extends FormData | {} | null | undefined,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  body,
  headers,
  method,
  pathParams,
  queryParams,
  signal,
  url,
  withCode,
}: CalendarFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams,
  boolean
>): Promise<TData | { data: TData; status: number }> {
  try {
    const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...headers,
    };

    /**
     * As the fetch API is being used, when multipart/form-data is specified
     * the Content-Type header must be deleted so that the browser can set
     * the correct boundary.
     * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    if (
      requestHeaders['Content-Type']
        .toLowerCase()
        .includes('multipart/form-data')
    ) {
      delete requestHeaders['Content-Type'];
    }

    const resolvedUrl = resolveUrl(url, queryParams, pathParams);

    const response = await window.fetch(`${baseUrl}${resolvedUrl}`, {
      body: body
        ? body instanceof FormData
          ? body
          : JSON.stringify(body)
        : undefined,
      headers: requestHeaders,
      method: method.toUpperCase(),
      signal,
    });
    if (!response.ok) {
      if (response.status === 500) {
        throw { payload: 'Unexpected error', status: 500 };
      }

      let error: ErrorWrapper<TError>;
      try {
        const payload = await response.json();
        error = { payload, status: response.status };
      } catch (e) {
        error = {
          payload:
            e instanceof Error
              ? `Unexpected error (${e.message})`
              : 'Unexpected error',
          status: response.status,
        };
      }

      captureException(
        new Error(`API Error for ${method.toUpperCase()} ${resolvedUrl}`),
        {
          extra: error,
        },
      );

      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      const data = (await response.json()) as TData;
      if (withCode) {
        return { data, status: response.status };
      }
      return data;
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      const data = (await response.blob()) as unknown as TData;
      if (withCode) {
        return { data, status: response.status };
      }
      return data;
    }
  } catch (e) {
    if (Object.prototype.hasOwnProperty.call(e, 'status')) {
      captureException(e);
      throw e;
    }
    const errorObject: Error = {
      message:
        e instanceof Error ? `Network error (${e.message})` : 'Network error',
      name: 'unknown' as const,
      stack: e as string,
    };

    captureException(errorObject);
    throw errorObject;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {},
) => {
  // By default, URLSearchParams serializes an array as key=val1,val2,val3,
  // but our backend expects key[]=val1,val2,val3.
  const searchParams = new URLSearchParams();
  Object.entries(queryParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(`${key}[]`, item);
      });
    } else {
      searchParams.set(key, value);
    }
  });
  let query = searchParams.toString();
  if (query) {
    query = `?${query}`;
  }
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
