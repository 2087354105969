import { useEffect } from 'react';

import { Outlet, createFileRoute, useNavigate } from '@tanstack/react-router';

import { getWebApp } from '@tg-web/utils';

import { AuthProvider } from '../features/auth';
import { GoogleConnectionHandler } from '../features/google';
import { AdditionalScrollFixer } from '../shared/ui/AdditionalScrollFixer';

export const Route = createFileRoute('/_authorized')({
  beforeLoad: ({ cause }) => {
    // if (!checkAuth()) {
    //   throw redirect({
    //     to: '/',
    //   });
    // }
    //
    // const { performed } = useInitialRedirectStore.getState();
    // const startParam = getWebApp().initDataUnsafe.start_param;
    //
    // if (!performed && startParam) {
    //   const paramObject = parseStartappParam(startParam);
    //   throw redirect({
    //     params: { eventId: paramObject.id },
    //     to: '/events/view/$eventId',
    //   });
    // }
    //
    // useInitialRedirectStore.getState().redirectHandled();
  },
  // AuthProvider should wrap layout, because AuthLayout has api requests
  component: () => (
    <AuthProvider>
      <AuthLayout />
    </AuthProvider>
  ),
});

function AuthLayout() {
  const navigate = useNavigate();

  useEffect(() => {
    const openSettings = () => {
      navigate({ to: '/settings' });
    };
    getWebApp().SettingsButton.show();
    getWebApp().SettingsButton.onClick(openSettings);

    return () => {
      getWebApp().SettingsButton.offClick(openSettings);
      getWebApp().SettingsButton.hide();
    };
  }, [navigate]);

  return (
    <AdditionalScrollFixer>
      <GoogleConnectionHandler>
        <Outlet />
      </GoogleConnectionHandler>
    </AdditionalScrollFixer>
  );
}
