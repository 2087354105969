import { PropsWithChildren } from 'react';

import { PropsWithCN, cn } from '@tg-web/utils';

type PageWrapperProps = PropsWithChildren & PropsWithCN;

export function PageWrapper({ children, className }: PageWrapperProps) {
  return (
    <div className={cn('h-full overflow-scroll', className)}>{children}</div>
  );
}
