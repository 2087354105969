import React from 'react';

import { IconProps } from './types';

export const ClockIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M15 8.0415C16.4948 10.6092 16.599 13.4998 16 16.0207C17.9271 15.3123 20.2135 15.3228 22 15.9998"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M28 16C28 22.625 22.625 28 16 28C9.375 28 4 22.625 4 16C4 9.375 9.375 4 16 4C22.625 4 28 9.375 28 16Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </svg>
    );
  },
);
