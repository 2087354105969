import { createFileRoute } from '@tanstack/react-router';

import { SlotsPage } from '../../../pages/slots/ui/SlotsPage';

export const Route = createFileRoute('/_authorized/_withTabs/slots')({
  component: Page,
});

function Page() {
  return <SlotsPage />;
}
