import { useTranslation } from 'react-i18next';

import { HourglassIcon } from '@tg-web/icons';

export function SlotsIsOffPanel() {
  const { t } = useTranslation();

  return (
    <div className="bg-tg-section-bg flex min-h-[268px] flex-col items-center justify-center gap-4 rounded-2xl p-8">
      <HourglassIcon />
      <p className="typo-text">{t('all:slots.view.is_off')}</p>
    </div>
  );
}
