import { Outlet, createFileRoute, useMatchRoute } from '@tanstack/react-router';

import {
  MainTabs,
  TabsValues,
  tabsToRoutesMap,
} from '../../widgets/layout/ui/MainTabs';

export const Route = createFileRoute('/_authorized/_withTabs')({
  component: Component,
});

function Component() {
  const matchRoute = useMatchRoute();
  const [initialTab] = Array.from(tabsToRoutesMap).find(([_, route]) =>
    matchRoute({ to: route }),
  ) ?? [TabsValues.feed];

  return (
    <>
      <Outlet />
      <MainTabs initialTab={initialTab} />
    </>
  );
}
