import { createFileRoute } from '@tanstack/react-router';

import { SlotsOnboardingPage } from '../../../pages/slots/ui/SlotsOnboardingPage';

export const Route = createFileRoute('/_authorized/slots/onboarding')({
  component: Page,
});

function Page() {
  return <SlotsOnboardingPage />;
}
